import React, { useCallback, useEffect, useState } from 'react';
import './addRegion.css';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";

function AddRegion(props) {
    const [selectedArray, setSelectedArray] = React.useState(props.data.data);
    const [isEdit] = React.useState(props.data.edit);
    const [regionData, setRegionData] = useState([])
    const [tableType, setTableType] = useState('1')
    const [regionError, setRegionError] = React.useState('')
    const [gbbError, setGbbError] = React.useState('')
    const [decimalErrorSetaside, setDecimalErrorSetaside] = React.useState('')
    const [decimalErrorGPUSetAsideGPT4, setDecimalErrorGPUSetAsideGPT4] = React.useState('')
    const [decimalErrorGPUGPT4Paygo, setDecimalErrorGPUGPT4Paygo] = React.useState('')
    const [decimalErrorFineTuning, setDecimalErrorFineTuning] = React.useState('')
    const [decimalErrorABCDModels, setDecimalErrorABCDModels] = React.useState('')
    const [decimalErrorGPUTurboPaygo, setDecimalErrorGPUTurboPaygo] = React.useState('')
    const [label, setLable] = useState([])
    const [uniqueGBBRegionIDs, setUniqueGBBRegionIDs] = useState([])
    const [updating, setUpdating] = useState(false)
 
    const navigate = useNavigate();
    function showToast(message, status) {
 
        const toaster = document.createElement('div');
        toaster.className = `toaster ${status}-toaster`;
        toaster.style.position = 'fixed';
        toaster.style.top = '5%';
        toaster.style.left = '50%';
        toaster.style.transform = 'translateX(-50%)';
 
        toaster.style.height = '35px';
        toaster.style.maxWidth = '300px';
        toaster.style.zIndex = 1000;
        toaster.innerText = message;
 
 
 
        document.body.appendChild(toaster);
        setTimeout(() => {
            toaster.style.display = 'none'; 
        }, 5000);
    }
 
 
    const getRegionList = async () => {
        const config = {
            headers: {
                'content-type': 'application/json',
                "serviceid": sessionStorage.getItem("userToken")
            }
        };
        axios.get(baseConfig.apiBaseUrl + '/Utility/Filter?columnName=Region', config)
            .then(response => {
                if (response.status === 200) {
                    setRegionData(response.data)
                }
 
            })
            .catch(function (error) {
                console.error(error);
                if (error?.response?.status === 401) {
                    navigate("/errorPage");
                }
            });
    };
 
 
    React.useEffect(() => {
        getRegionList()
        if (props.table.length !== 0) {
            setLable(Object.keys(props.table[0]))
            const uniqueGBBRegionID = [];
 
            props.table.forEach(obj => {
                if ('GBB Region' in obj && obj['GBB Region'].trim() !== '') {
                    if (!uniqueGBBRegionID.includes(obj['GBB Region'])) {
                        uniqueGBBRegionID.push(obj['GBB Region']);
                    }
                }
            });
            setUniqueGBBRegionIDs(uniqueGBBRegionID)
 
        }
 
    }, []);
    
    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            closeModal()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);
    function validate() {
        let isValid = true;
        if (!selectedArray || !selectedArray['Region'] || (tableType === '0' && (!selectedArray || !selectedArray['SellableETAmonth'])) || selectedArray['']) {
            if (!selectedArray || !selectedArray['Region']) {
                setRegionError('This is mandatory field')
                return isValid = false
            }
            else {
                setRegionError('')
            }
        }
        return isValid = true
    }
 
    const update = async () => {
 
        if (validate()) {
            setUpdating(true)
            showToast(isEdit ? "Updating Region" : "Adding Region", 'warning');


            let modifiedLabel = isEdit ? label : label.filter(item => item !== 'RegionID');
            modifiedLabel.map((lab, index) => selectedArray[lab] = selectedArray[lab] ? selectedArray[lab] : "")
            for (let key in selectedArray) {
                if (selectedArray.hasOwnProperty(key)) {
                    selectedArray[key] = (selectedArray[key] === "" || null ) ? (key === "GBB Region" ? "" : 0 ): selectedArray[key];
                }              
            }
            const postData = selectedArray;
            let path = ''
            {
                isEdit === true ?
                    path = '/CapacityRoadMap/Update'
                    :
                    path = '/CapacityRoadMap/Add'
 
                if (tableType === "1") {
                    postData["IsSellableNow"] = 1
                } else {
                    postData["IsSellableNow"] = 0
                }
            }
            try {
                const response = await axios.post(
                    baseConfig.apiBaseUrl + path,
                    postData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "serviceid": sessionStorage.getItem("userToken")
                        },
                    }
                );
                setUpdating(false)

 
                if (response.status === 200) {
                    props.handleModal()
                    showToast(response.data, 'success');
                    window.location.reload()
                }
 
                else {
                    navigate("/CapacityRoadMap");
                }
 
            } catch (error) {
                setUpdating(false)

                if (error.response.status === 404) {
                    showToast("Region already available, Try some other region", 'danger')
                }
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
 
        }
    }
 
    const closeModal = () => {
        props.handleModal()
    }
 
    const handleInputChange = (e, lab, index) => {
        const newValue = e.target.value;
        const updatedArray = { ...selectedArray };
        let errorMessage = '';
 
        if (index > 2 && lab !== 'GBB Region') {
 
            if (lab.includes('PTU')) {
                const parsedValue = parseInt(newValue, 10);
 
                if (newValue === '' || (!isNaN(parsedValue) && newValue === parsedValue.toString())) {
                    updatedArray[lab] = newValue === '' ? 0 : parsedValue;
                } else {
                    updatedArray[lab] = 0;
                    errorMessage = 'Decimal values are not allowed';
                }
 
                if (newValue !== '') {
                    switch (index) {
                        case 3:
                            setDecimalErrorSetaside(errorMessage);
                            break;
                        case 4:
                            setDecimalErrorGPUSetAsideGPT4(errorMessage);
                            break;
                        case 5:
                            setDecimalErrorABCDModels(errorMessage);
                            break;
                        case 6:
                            setDecimalErrorGPUGPT4Paygo(errorMessage);
                            break;
                        case 7:
                            setDecimalErrorFineTuning(errorMessage);
                            break;
                        case 8:
                            setDecimalErrorGPUTurboPaygo(errorMessage);
                            break;
                        default:
                            break;
                    }
                }
            }
        } else if (lab === 'Region') {
            if (/[^a-zA-Z0-9 ]/.test(newValue)) {
                errorMessage = 'Region name cannot contain special characters';
            } else if (/^\d/.test(newValue)) {
                errorMessage = 'Region name cannot start with a number';
            }
            updatedArray[lab] = newValue;
            setRegionError(errorMessage);
        } else {
            updatedArray[lab] = newValue || "";
        }
 
        setSelectedArray(updatedArray);
    };
 
    return (
        <div className="modal-container-add">
 
            <div style={{ maxHeight: '80vh' }}>
                <div className="title-add">
                    {isEdit === true ?
                        <>
                            <b>Allocate PTUs</b>
                            <i
                                className="fa fa-times close-icon"
                                onClick={closeModal}
                            ></i>
                        </>
                        :
                        <> <b>Add New Region</b>
                            <i
                                className="fa fa-times close-icon"
                                onClick={closeModal}
                            ></i>
                        </>
                    }
                </div>
 
                <div className="grid-container-add"  >
                    {label && label.map((lab, index) => {
                        if (index > 1 && lab === "Region") {
                            return (
                                <div className="grid-item-add" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px' }}>
                                    <div className="input-container-add">
                                        <label className="text-container-add">
                                            Region Name <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        {isEdit ? (
                                            <input
                                                placeholder="Enter Region Name"
                                                type="text"
                                                disabled={isEdit}
                                                value={selectedArray?.Region}
                                                style={{ width: '100%', cursor: 'not-allowed' }}
                                                className="custom-input-add"
                                                onChange={(e) => { handleInputChange(e, 'Region') }}
                                            />
                                        ) : (
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <input
                                                    placeholder="Choose / Enter Region Name"
                                                    type="text"
                                                    disabled={isEdit}
                                                    value={selectedArray?.Region}
                                                    style={{ width: '100%', cursor: isEdit ? 'not-allowed' : '' }}
                                                    className="custom-input-add"
                                                    onChange={(e) => { handleInputChange(e, 'Region') }}
                                                />
                                                <div style={{}} id="filterDropdownDiv">
                                                    <select name="filterDropDown" id="filterDropDown"
                                                        disabled={isEdit}
                                                        onChange={(e) => { handleInputChange(e, 'Region') }}
                                                        style={{ width: '20px', outline: 'none', marginTop: '3px', height: '32px', cursor: isEdit ? 'not-allowed' : 'pointer' }}>
                                                        <option value="-1" selected="selected" disabled="disabled">-- Select Region name --</option>
                                                        {regionData?.map((Region) => (
                                                            <option key={Region.Key} value={Region.Value}>{Region.Value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <span style={{ color: 'red', fontSize: '8px', display: 'block', marginTop: '3px' }}>   {regionError}</span>
                                    </div>
                                </div>
                            )
                        }
                        if (index > 2 && !lab.includes("GBB Region")) {
                            return (
                                <>
                                    <div className="grid-item-add">
                                        <div className="input-container-add">
                                            <label className="text-container-add">{lab}</label>
                                            <input
                                                type="number"
                                                defaultValue={selectedArray?.[lab]}
                                                placeholder="Enter number"
                                                className="custom-input-add"
                                                style={{ width: '100%', }}
                                                onChange={(e) => { handleInputChange(e, lab, index) }}
                                            />
                                            <span style={{ color: 'red', fontSize: '8px', display: 'block', marginTop: '3px' }}>
                                                {index === 3 ? decimalErrorSetaside :
                                                    index === 4 ? decimalErrorGPUSetAsideGPT4 :
                                                        index === 5 ? decimalErrorABCDModels :
                                                            index === 6 ? decimalErrorGPUGPT4Paygo :
                                                                index === 7 ? decimalErrorFineTuning :
                                                                    index === 8 ? decimalErrorGPUTurboPaygo :
                                                                        ''
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>)
                        }
                        if (lab.includes("GBB Region")) {
                            return (
                                <div className="grid-item-add">
                                    <div className="input-container-add">
                                        <label className="text-container-add"><span style={{ color: 'red' }}></span>GBB Region</label>
                                        {isEdit ? (
                                            <input
                                                placeholder="Enter GBB Region"
                                                type="text"
                                                disabled={isEdit}
                                                value={selectedArray?.['GBB Region']}
                                                style={{ width: '100%', cursor: 'not-allowed' }}
                                                className="custom-input-add"
                                                onChange={(e) => { handleInputChange(e, 'GBB Region') }}
                                            />
                                        ) : (
                                            <div className='GBB-margin' style={{ flexDirection: 'row', display: 'flex' }}>
                                                <input
                                                    placeholder="Choose / Enter GBB Region"
                                                    type="text"
                                                    disabled={isEdit}
                                                    value={selectedArray?.['GBB Region']}
                                                    style={{ width: '100%', cursor: isEdit ? 'not-allowed' : '' }}
                                                    className="custom-input-add"
                                                    onChange={(e) => { handleInputChange(e, 'GBB Region') }}
                                                />
                                                <div style={{}} id="filterDropdownDiv">
                                                    <select name="filterDropDown" id="filterDropDown"
                                                        disabled={isEdit}
                                                        onChange={(e) => { handleInputChange(e, 'GBB Region') }}
                                                        style={{ width: '20px', outline: 'none', marginTop: '3px', height: '32px', cursor: isEdit ? 'not-allowed' : 'pointer' }}>
                                                        <option value="-1" selected="selected" disabled="disabled">-- Select GBB Region ID --</option>
                                                        {uniqueGBBRegionIDs?.map((Region, key) => (
                                                            <option key={key} value={Region}>{Region}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <span style={{ color: 'red', fontSize: '8px', display: 'block', marginTop: '3px' }}>   {gbbError}</span>
                                    </div>
                                </div>
                            )
                        }
                    })}
 
                </div>

                <div className="grid-container-add">

                </div>
                 
                <hr className="divider-add" />
                <div className="buttons-container-add marginBottom">
                    <button
                        className="button-add button-go-back-add"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="button-add button-confirm-add"
                        onClick={update}
                        disabled={regionError !== '' || updating ? true : false}
                        style={{ opacity: regionError !== '' ? 0.5 : 1 }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div >
    );
}
 
export default AddRegion;