import React, { useCallback, useEffect, useState } from 'react';
import './requestApproval.css';
import ApprovalTable from '../../components/tableComponent/ApprovalTable';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";
function ApproveRequest(props) {
    const [selectedArray, setSelectedArray] = React.useState(props.data?.filter((item, index) => props.selectedIndex?.includes(item.RequestID)));
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [allocating, setAllocating] = useState(false)

    const navigate = useNavigate();
    const update = async () => {
        setAllocating(true)
        if (props.selectedIndex) {
            const filteredData = selectedArray.map((item) => ({
                RequestID: item.RequestID,
                RequestGrantStatusID: item.RequestGrantStatusID,
                DateApproved: item.DateApproved,
                DRI_Alias: item.DRI_Alias,
                DRI_Name: item.DRI_Name,
                AssignedToAlias: item.AssignedToAlias,
                AssignedToName: item.AssignedToName,
                Notes: item.Notes,
                ModelID: item.ModelID,
                Tag: item.Tag,
                ModelName: item.ModelName,
                RegionGrantedID: item.RegionGrantedID || -1,
                PTU_Granted: item.PTU_Granted || '',
            }));
            try {
                const response = await axios.post(
                    baseConfig.apiBaseUrl + '/HelpRequest/Approve',
                    filteredData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "serviceid": sessionStorage.getItem("userToken")
                        },
                    }
                );
                if (response.status === 200) {
                    setAllocating(false)
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(false)
                        window.location.reload();
                    }, 3000);
                }

            } catch (error) {
                console.error('API Error:', error);
                setAllocating(false)
                setFailed(true)
                setTimeout(() => {
                    setFailed(false)
                }, 3000);
                // if (error.response.status === 404) {
                //     showToast(error.response.data)
                // }
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
        }
        else {
            window.location.reload();
        }
    }

    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            props.closeConfirmationModal()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);

    return (
        <>
            <div className="modal-container">
                <div style={{ maxHeight: '80vh' }}>
                    <div className="title">
                        <b>Approve the Request</b>
                        <i className="fa fa-times close-icon"
                            onClick={props.closeConfirmationModal}
                        ></i>
                    </div>

                    <hr className="divider" />

                    <div className="text" style={{ fontSize: '13.5px' }}>

                        You have selected "Manual entry" from Allocation Mode. Do you want to approve the changes? If so, please click the <b>"Approve"</b> button at the bottom of the table. If you want to cancel the request, then click the <b>"Cancel"</b> button.


                        {/* You have Selected Manual entry from Allocation Mode. Do you want to
                        Approve the Changes? If so, please Click <b>Approve</b>{' '}
                        button at the bottom of the table. If you want to cancel the request
                        then Click on <b>Cancel</b> Button. */}
                    </div>

                    <div style={{ overflow: 'scroll', maxHeight: '300px' }}>
                        <ApprovalTable data={selectedArray} isMail={false}></ApprovalTable>
                    </div>

                    <hr className="divider" />

                    <div className="buttons-container">
                        <button
                            className="button button-go-back"
                            onClick={props.closeConfirmationModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button button-confirm"
                            onClick={update}
                            disabled={allocating ? true : false}
                        ><span>    Approve</span>

                            <span className="tooltip-text">Confirm Allocation</span>
                        </button>
                    </div>
                </div>
            </div>
            {success && <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                Record Updated Successfully
            </div>}
            {allocating && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                Approving...
            </div>}
            {failed && <div className="alert">
                Failed To Update Record. Try again.
            </div>}
        </>
    );
}

export default ApproveRequest;
