import ApprovalTable from '../../components/tableComponent/ApprovalTable';
import './mail.css';
import baseConfig from '../../../config/config';
import axios from 'axios';
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { tableFormat } from '../../../config/tableFormat';
import { useNavigate } from "react-router-dom";
import Reactselect from 'react-select';
import { useMsal } from '@azure/msal-react';
const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Segoe UI',
        width: '100%', 
        border: 'none',
        borderRadius: '5px',
        boxShadow: state.isFocused ? "0px 0px 0px black" : "none",
        "&:hover": {
            border: "1px solid #888888"
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        fontFamily: 'Segoe UI',
        width: '100%', 
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)'
    }),
};
function Mail(props) {

    function showToast(message,status) {
        const toaster = document.createElement('div');
        toaster.className = `toaster ${status}-toaster`;
        toaster.style.position = 'fixed';
        toaster.style.top = '5%';
        toaster.style.left = '50%';
        toaster.style.transform = 'translateX(-50%)';

        toaster.style.height = '35px'; 
        toaster.style.maxWidth = '300px';
toaster.style.zIndex=1000;
        toaster.innerText = message;



        document.body.appendChild(toaster);
        setTimeout(() => {
            toaster.style.display = 'none'; 
        }, 5000);
    }




    const [modalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [options, setOptions] = React.useState([]);
    const navigate = useNavigate();
    const [to, setTo] = useState('');
    const [cc, setCc] = useState('');
    const [subject, setSubject] = useState('');
    const [isToValid, setIsToValid] = useState(true);
    // const [isCcValid, setIsCcValid] = useState(true);
    const [isSubjectValid, setIsSubjectValid] = useState(true);
    const [emailValue, setEmailValue] = React.useState('')
    const [sending, setSending] = useState(false)
    const { instance, inProgress, accounts } = useMsal();
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const [loader, setLoader] = React.useState(false)

    const isToRequired = true;
    const isCcRequired = true;
    const isSubjectRequired = true;
    let selectRef = null;
    // const [isSubjectFilled, setIsSubjectFilled] = useState(true);
    // const [isSubjectBlank, setIsSubjectBlank] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isToEmpty, setIsToEmpty] = useState(false); // State to track if "To" is empty

    const handleSendMailClick = () => {
        // Check if "To" and "Subject" fields are filled
        if (toEmails.length > 0 && subject.trim() !== '') {
            setSending(true)
            showToast('Sending Email...','warning');
            update();
        } else {
            // Show a modal or message indicating that both fields are required
            setIsModalOpen(true);
        }
    };
    const update = async () => {

        const isToEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(to);
        const isCcEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cc);

        const isToRequired = true;
        const isCcRequired = true;

        if (isToRequired && !to.trim()) {
            setIsToValid(false);

        } else {
            setIsToValid(isToEmailValid);
        }

        const isSubjectRequired = true; // You can set this based on your requirements
        if (isSubjectRequired && !subject.trim()) {
            setIsSubjectValid(false);
            // window.alert('Subject is required.'); 
        } else {
            setIsSubjectValid(true);
        }

        if (isToValid && isSubjectValid) {
            const TableData =  generateTableData();
            const toEmailsString = toEmails.join(',');
 
 
            const apiDataTemp = {
                "EmailTo": toEmailsString,
                "EmailCc": ccEmails.join(','),
                // "EmailBcc": '',
                "EmailSubject": subject,
                "EmailBody": text,
                "TableData": TableData
            };

            try {
                const response = await axios.post(baseConfig.apiBaseUrl + '/Email/Add', apiDataTemp, {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": sessionStorage.getItem("userToken")
                    },
                });
                setSending(false)

                if (response.status === 200) {
                    showToast('Email sent successfully','success');
                    setTo('');
                    setCc('');
                    setSubject('');
                    setText('');
                    setModalOpen(false);
                }

            } catch (error) {
                if (error.response.status === 404) {
                    showToast(error.response.data,'danger');
                }
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
        }
        props.closeMailModal();
    };


    const handleDontSendClick = () => {
        setIsModalOpen(false);

    };
    const handleInputChange = (e, selectedOption) => {
        const newValue = e.target.value;
        setSubject(newValue)
        if (selectedOption) {
            const newEmail = selectedOption.value;
            setToEmails([...toEmails, newEmail]);
        }
    };



    const [text, setText] = useState('');
    const onEditorStateChange = (editorState) => {
        if (editorState.replace(/<[^>]+>/g, '')?.trim() === '') {
            editorState = ''
        }
        setText(editorState);
    };

    function generateHTMLTable(data) {
        if (!data || data.length === 0) {
            return '<p>No data available</p>';
        }

        const tableHeaders = Object.keys(data[0]);

        const tableRows = data.map((item, index) =>{
            return (
                `<tr key=${index}>
                  ${tableHeaders.map((header) => `<td>${item[header] || 'NA'}</td>`).join('')}
                </tr>`
            )
        } );

        const tableHeaderRow = `
        <tr>
            ${tableHeaders.map((header) => `<th>${header}</th>`).join('')}
        </tr>
        `;

        return `
        <html>
        <body>
        <style>
            th {
            font-Family: 'Segoe UI';
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            background-color: #e9e9e9;
            color: black;
            height: 30px;
            padding: 10px;
            margin-left: 20px;}

            td {font-family: Segoe UI;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            height: 30px;
            padding: 10px;
            margin-left: 20px;}


            </style>
            <table style = "border: 5px solid #CCCCCC; width: 100%; margin-left: 20px; overflow-x:scroll;">
            <thead style = "background-color:#e9e9e9;  margin-left: 20px; position: 'sticky'; top: '0'; zIndex: '1'">
                ${tableHeaderRow}
            </thead>
            <tbody>
            ${tableRows.join('\n')}
            </tbody>
            </table>
        </body>
        </html>
        `;
    }
    const [selectedData, setSelectedEntries] = React.useState([]);
    const [responseData, setTableData] = React.useState([])
    const getAllData = async () => {
        setLoader(true)
        const config = {

            headers: {

                'content-type': 'application/json',
                "serviceid": sessionStorage.getItem("userToken")

            }

        };
        const tabValue = parseInt(sessionStorage.getItem('tab'));
        if (tabValue === 3) {
            const filteredData = props.selectedData.map(item => ({
                'Customer Type': item.CustomerType,
                'Request ID': item.RequestID,
                'Customer': item.CustomerName,
                'SUB ID': item.SubscriptionID,
                'Model': item.ModelName,
                'Region': item.Region
            }));
            setTableData(filteredData);
            setLoader(false);
            return; 
        }
        const nextURL = parseInt(sessionStorage.getItem('tab'))===2?'/HelpRequest/List?Source=PTUManagedTriage':'/HelpRequest/List?Source=PTUTriage';
        axios.get(baseConfig.apiBaseUrl + nextURL, config)

            .then(response => {

                if (response.status === 200) {
                    setTableData(response.data)
                    setLoader(false)
                }


            })

            .catch(function (error) {
                setLoader(false)
                console.error("mailError",error);
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            });

    };

    function generateTableData() {        
        const tab = sessionStorage.getItem('tab');
        const tableData = responseData;
        if (tab === '3') {
            const excludeColumns = [
                "RowID",
                "RequestDate",
                "Tag",
                "RequestGranted",
                "RequestState",
                "ApprovedDate",
                "ProvisionedDate",
                "DeprovisionedDate",
                "CustomerEmail",
                "Notes"
            ];
        
            // Filter responseData based on excludeColumns
            const MailTable = responseData.map(item => {
                // Filter out excluded keys
                const filteredItem = Object.keys(item).reduce((acc, key) => {
                    if (!excludeColumns.includes(key)) {
                        acc[key] = item[key]; // Add remaining keys to the new object
                    }
                    return acc;
                }, {});
                return filteredItem;
            });
            setSelectedEntries(MailTable);
            return generateHTMLTable(MailTable);
        }
        else{
            const selectedIndex = props.selectedIndex;
            const selectedEntries = tableData.filter(item => 
                selectedIndex.includes(item.RequestID)
            );
            const excludeColumns = ['DeprovisionDate', 'RequestGrantStatus', 'Notes', 'PurchaseDate', 'ChurnDate', 'ChurnReason'];
            const MailTable = selectedEntries.map(item => {
                return tableFormat.keys.reduce((acc, key, index) => {
                    if (!excludeColumns.includes(key)) {
                        acc[tableFormat.headerLabels[index]] = item[key];
                    }
                    return acc;
                }, {});
            });
            setSelectedEntries(MailTable);
            return generateHTMLTable(MailTable);
        }
    }
    const [toEmails, setToEmails] = useState([]);
    const [toInput, setToInput] = useState('');

    const handleToInputChange = (e) => {
        const newValue = e?.mail || ''
        if (newValue !== '') {
            document.getElementById('btn').focus();
        }else{
            return
        }
        setToInput(newValue);
        fetchData(newValue);
        setToEmails([...toEmails, newValue])
        setOptions([]);
    };

    const handleToInputKeyUp = (e) => {
        if (e.key === 'Enter' && toInput.trim() !== '') {
            setToEmails([...toEmails, toInput]);
            setToInput('');
        }
    };


    const handleRemoveToEmail = (index) => {
        const updatedToEmails = [...toEmails];
        updatedToEmails.splice(index, 1);
        setToEmails(updatedToEmails);
    };


    const [ccEmails, setCCEmails] = useState([]);
    const [ccInput, setCCInput] = useState('');


    const handleCCInputChange = (e) => {
        const newValue = e?.mail || ''
        if(!newValue) return;
        setCCInput(newValue);
        fetchData(newValue);
        setCCEmails([...ccEmails,newValue])
        setOptions([]);
    };

    const handleCCInputKeyUp = (e) => {
        if (e.key === 'Enter' && ccInput.trim() !== '') {
            setCCEmails([...ccEmails, ccInput]);
            setCCInput('');
        }
    };


    const handleRemoveCCEmail = (index) => {
        const updatedCCEmails = [...ccEmails];
        updatedCCEmails.splice(index, 1);
        setCCEmails(updatedCCEmails);
    };

    const fetchData = async (value) => {
        var successfulSignIn = false;
        try {
            if (accounts.length > 0) {
                const accessTokenRequest = {
                    scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
                    account: accounts ? accounts[0] : null,
                };
                const response = await instance.acquireTokenSilent(accessTokenRequest);
                sessionStorage.setItem("userName", response.account.name);
                sessionStorage.setItem("Emailid", response.account.username);
                sessionStorage.setItem("accountId", response.account.localAccountId);
                sessionStorage.setItem("userToken", response.accessToken);
                setIsSignedIn(true)
                successfulSignIn = true
            }
            else {
                throw Error("not found any current active account")
            }
        } catch (error) {
            console.log('Error acquiring token silently:', error);
            sessionStorage.clear();
            window.location.replace('/');
        }
        if (successfulSignIn) {
            const url =
                `https://graph.microsoft.com/v1.0/users?$count=true&$filter=(startsWith(displayName,'${value}') or startsWith(mail,'${value}'))`;
            const headers = {
                ConsistencyLevel: "eventual",
                Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
                "Content-Type": "application/json",
                "serviceid": sessionStorage.getItem("userToken")
            };
            try {
                const response = await axios.get(url, { headers });
                let filterData = response?.data?.value.filter(item => baseConfig.allowedDomains.some(domain => item?.mail?.includes(domain)));
                setOptions(filterData)
            } catch (error) {
                console.error(error);
                setOptions([])
            }
        }
    };


    React.useEffect(() => {
        getAllData();
    }, []);

    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
          props.closeMailModal()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);


    React.useEffect(() => {
        generateTableData();

    }, [responseData]);


    return (
        <div className="modal-container" >
            <div style={{ maxHeight: '100vh', marginBottom: '10px' }}>
                <div className="title">
                    <b>New mail</b>
                    <i className="fa fa-times close-icon"
                        onClick={props.closeMailModal}
                    ></i>
                </div>
                <hr className="divider" />
                <div >


                    <div className="grid-container-m">
                        <div className="grid-item" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px', width:"auto" }}>
                            <div className="input-container-bulk-m">
                                <div className="input-header">
                                    <label className="text-container-mail">To</label>
                                    <div className="input-line">
                                        {toEmails.map((email, index) => (
                                            <div className="email-chip" key={index}>
                                                <div className="email-chip-content">
                                                    {email}
                                                    <span className="remove-chip" onClick={() => handleRemoveToEmail(index)}>
                                                        &times;
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                        <Reactselect
                                            styles={reactSelectCustomStyles}
                                            // onChange={handleToInputChange}
                                            onChange={(selectedOption) => {
                                                // setSelectedToOption(null); // Clear the selected option
                                                handleToInputChange(selectedOption);
                                            }}
                                            onInputChange={(searchString) => {
                                                fetchData(searchString)
                                            }}

                                            className={`custom-input-m ${(!isToValid && toEmails.length === 0) ? 'invalid-m' : ''}`}
                                            ref={e => (selectRef = e)}
                                            onKeyUp={handleToInputKeyUp}
                                            // onBlur={handleToInputBlur}
                                            isClearable
                                            value={emailValue || ''}
                                            options={options.map((option) => ({
                                                value: option.displayName + " | " + option.mail,
                                                displayName: option.displayName || "",
                                                mail: option.mail || "",
                                                localId: option.localId || "",

                                                label: (
                                                    <>
                                                        <div style={{ marginTop: '0.2rem', }}>
                                                            <div id='btn'>
                                                                <b>
                                                                    <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                </b>
                                                                <br />
                                                                <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }))}
                                            placeholder="Enter email addresses (press Enter to add)"
                                        />
                                    </div>
                                </div>
                                {isToValid || !isToRequired || toEmails.length > 0 ? null : (
                                    <div className="required-field-asterisk red-text">Required</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="grid-container-m">
                        <div className="grid-item" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px', width:"auto" }}>
                            <div className="input-container-bulk-m">
                                <div className="input-header">
                                    <label className="text-container-cc-Subject">Cc</label>
                                    <div className="input-line">
                                        {ccEmails.map((email, index) => (
                                            <div className="email-chip" key={index}>
                                                <div className="email-chip-content">
                                                    {email}
                                                    <span className="remove-chip" onClick={() => handleRemoveCCEmail(index)}>
                                                        &times;
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <input
                                            type="text"
                                            placeholder="Enter email addresses (press Enter to add)"
                                            className={`custom-input-m ${(!isCcValid && ccEmails.length === 0) ? 'invalid-m' : ''}`}
                                            style={{ width: '90vh', marginTop: '5px' }}
                                            value={ccInput}
                                            onChange={handleCCInputChange}
                                            onKeyUp={handleCCInputKeyUp}
                                            onBlur={handleCcInputBlur}
                                        /> */}
                                        <Reactselect
                                            styles={reactSelectCustomStyles}
                                            // onChange={handleCCInputChange}
                                            onChange={(selectedOption) => {
                                                // setSelectedToOption(null); // Clear the selected option
                                                handleCCInputChange(selectedOption);
                                            }}
                                            onInputChange={(searchString) => {
                                                fetchData(searchString)
                                            }}
                                            className={`custom-input-m `}
                                            onKeyUp={handleCCInputKeyUp}
                                            // onBlur={handleCcInputBlur}
                                            isClearable
                                            value={emailValue || ''}
                                            options={options.map((option) => ({
                                                value: option.displayName + " | " + option.mail,
                                                displayName: option.displayName || "",
                                                mail: option.mail || "",
                                                localId: option.localId || "",
                                                label: (
                                                    <>
                                                        <div style={{ marginTop: '0.2rem' }}>
                                                            <div>
                                                                <b>
                                                                    <span style={{ fontSize: 16 }}>{option.displayName}</span>
                                                                </b>
                                                                <br />
                                                                <span style={{ fontSize: 11, color: 'grey', marginTop: '5rem' }}>{option.mail}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            }))}
                                            placeholder="Enter email addresses (press Enter to add)"
                                        />
                                    </div>
                                </div>
                                {/* {isCcValid || !isCcRequired || ccEmails.length > 0 ? null : (
                                    <div className="required-field-asterisk red-text">Required</div>
                                )} */}
                            </div>
                        </div>
                    </div>

                    <div className="grid-container-m">
                        <div className="grid-item" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px', width:"auto" }}>
                            <div className="input-container-bulk-m">
                                <div className="input-header">
                                    <label className="text-container-mail">Subject</label>
                                    <div className="input-line">
                                        <input
                                            type="text"
                                            placeholder="Enter text"
                                            className={`custom-input-m ${!isSubjectValid ? 'invalid-m' : ''}`}
                                            style={{ width: '90vh', marginTop: '5px' }}
                                            value={subject}
                                            onChange={(e) => { handleInputChange(e) }}
                                        />
                                    </div>
                                </div>
                                {isSubjectValid || !isSubjectRequired ? null : (
                                    <div className="required-field-asterisk red-text">Required</div>
                                )}
                            </div>
                        </div>
                    </div>


                    <div style={{ marginLeft: '5px', marginRight: '5px', marginTop: '5px', marginBottom: '5px', overflow: selectedData.length > 0? 'auto' : "none", maxHeight: '40vh' }}>
                        {selectedData.length > 0 && (
                        <ApprovalTable data={selectedData} isMail={true}></ApprovalTable>

                        )}
                        {
                            loader &&
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        }
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        height: "100%",
                    }}>
                        <div className="grid-item" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '10vh', width: '180vh' }}>
                            <div className="input-container-bulk-m" style={{ width: '100%', height: '100%', flex: '1' }}>
                                <ReactQuill
                                    value={text}
                                    theme='snow'
                                    style={{ width: '100%', height: '20vh', marginTop: '5px', flex: '1' }}
                                    onChange={(editorState) => { onEditorStateChange(editorState) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttons-container" >
                        <button
                            style={{ marginBottom: '1vh' }}
                            className="button button-go-back"
                            onClick={props.closeMailModal}

                        >
                            Cancel
                        </button>
                        <button
                            style={{ marginBottom: '1vh' }}
                            className="button button-confirm"
                            onClick={handleSendMailClick}
                            disabled={sending ? true : false}
                        >
                            Send Mail
                        </button>

                    </div>

                </div >


            </div>
            {isModalOpen && (
                <div className="modal-containerMail">
                    <i className="fa fa-times close-buttonMail" aria-hidden="true" onClick={handleDontSendClick}></i>
                    <div className="modal-contentMail">
                        <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'yellow', textShadow: '1px 1px 0px black' }}></i>
                        <span style={{ paddingLeft: '10px' }}>Please fill all the mandatory fields</span>
                        <div className="buttons-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', marginRight: '70px' }}>
                            <button
                                style={{ marginBottom: '1vh' }}
                                className="button button-confirm"
                                onClick={handleDontSendClick} > Cancel
                            </button>

                        </div>
                    </div>
                </div>
            )}

            {successMessage && <div className="toaster success-toaster">{successMessage}</div>}

        </div >


    );

}

export default Mail;