import React, { useEffect, useState } from 'react';
import Detail from './../../../pages/detail/detail';
import { tableFormat } from '../../../../config/tableFormat';
import "./tablecomponant.css"
import "./mastertable.css"
import { useDispatch, useSelector } from 'react-redux';
import { updateAllManagedTableData, updateAllClassicTableData, removePtusFromManagedRegion, removePtusFromClassicRegion } from '../../../../redux/reducers/rootReducer';
import Draggable from './Draggable';
import Select from 'react-select';
import { components } from 'react-select';

const CustomValueContainer = ({ children, ...props }) => {
    const selectedValues = props.getValue();
    const { selectProps, hasValue } = props;

    return (
        <components.ValueContainer {...props}>
            <div className="drag-tag">
                <Draggable showScroll={false}>
                    {!hasValue && !props.selectProps.inputValue && (
                        <div className="custom-placeholder">{selectProps.placeholder}</div>
                    )}
                    {selectedValues.map((val, index) => (
                        <span className="tag-value" key={val.value} style={{ marginRight: '8px' }}>
                            {val.label}
                        </span>
                    ))}
                </Draggable>
            </div>
        </components.ValueContainer>
    );
};
const customStyles = {
    control: (provided) => ({
        ...provided,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        border: 'none',
        padding:"0",
        boxShadow: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
        color: state.isSelected ? 'black' : provided.color,
        cursor: 'pointer',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        flexWrap: 'unset',
        fontFamily:
            'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
        fontSize: '5px',
        fontWeight: '600',
        // height: '32px',
        // padding: '0px 8px 0px 8px',
        width: '18.5vw',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'black',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'black',
        ':hover': {
            backgroundColor: 'lightgray',
            color: 'black',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensure the menu is on top
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensure the menu portal is on top
    }),
};

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label style={{ fontSize: "0.9rem", marginLeft: "2%" }}>{props.label}</label>
            </div>
        </components.Option>
    );
};

const AddComponent = (props) => {

    const [errorLabel, setErrorLabel] = React.useState("");
    const [errorRow, setErrorRow] = React.useState("");
    const [headerLabels, setHeaderLabels] = React.useState([]);
    const [keys, setKeys] = React.useState([]);

    const [selectedRows, setSelectedRows] = useState(props.selectedIndex || []);
    const [showBackDrop, setShowBackDrop] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [rowData, setrowData] = React.useState(null);
    const regionGrantedStatusOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestGrantStatus !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestGrantStatus : null);
    const classicModelNameOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.ClassicModelName !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.ClassicModelName : null);
    const [regionGrantedOptions, setRegionGrantedOptions] = React.useState();
    const [tagsOptions, setTagsOptions] = React.useState([]);
    const PTUManagedTriageTagsReducer = props.PTUTagsReducer;
    const modelOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.ModelName !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.ModelName : null);
    const gbbRegionOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.GBBRegion !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.GBBRegion : null);
    const requestStateOption = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestState !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestState : null);
    const dispatch = useDispatch();
    const [sortConfig, setSortConfig] = useState({ column: null, order: 'asc' });
    const [changedModel, setChangedModel] = useState(true);
    const managedTableDataReducer = useSelector(state => state.tableData.tableData);
    const EATableDataReducer = useSelector((store) =>
                store.eaTableData.tableData !== undefined
                      ? store.eaTableData.tableData
                      : []
          );
    const classicTableDataReducer = useSelector(store => store.classicTableData.classicTableData !== undefined ? store.classicTableData.classicTableData : []);
    const allRegionsData = useSelector(state => state.ptutable.ptuData);
    const [previousDatasOfSelectedRows, setPreviousDatasOfSelectedRows] = React.useState([]);
    const AllManageSubmissionsRegionGrantedOptions = useSelector((store) =>
        store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.PTUManagedTriageRegionGranted !== undefined
            ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.PTUManagedTriageRegionGranted
            : null
    );
    const classicRegionGrantedOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.PTUTriageRegionGranted !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.PTUTriageRegionGranted : null);
    const reorderOptions = (options, labels) => {
        const labelValuePair = labels.map(label => {
            const tag = options.find(option => option.Name === label.trim())
            return {
                value: tag.ID,
                label: tag.Name
            }
        });
        return labelValuePair
    };

    const MultiSelectCheckbox = ({ selectedOptions, options, onChange }) => {
        const formattedOptions = options.map(option => ({
            value: option.ID,
            label: option.Name
        }));
        var selectedList = [];
        if (selectedOptions) {
            selectedList = Array.isArray(selectedOptions) && (typeof selectedOptions[0] === 'string') ? reorderOptions(PTUManagedTriageTagsReducer, selectedOptions) : selectedOptions;
        }

        return (
            <Select
                closeMenuOnSelect={false}
                components={{ Option: CustomOption, ValueContainer: CustomValueContainer }}
                isMulti
                options={formattedOptions}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                styles={customStyles}
                onChange={onChange}
                value={selectedList}
                inputValueRenderer={selectedOptions}
                placeholder="Choose Tags"
                menuPortalTarget={document.body} // Render the menu to the end of the body to ensure it isn't hidden
            />
        );
    };
    const handleSort = (column) => {
        let order = 'asc';
        if (sortConfig.column === column && sortConfig.order === 'asc') {
            order = 'desc';
        }
        setSortConfig({ column, order });
    };

    const getDateValue = (dateString) => {
        if (!dateString) return null;
        const [month, day, year] = dateString.split('/').map(Number);
        const dateObject = new Date(year, month - 1, day);
        return dateObject.getTime();
    };
    const sortedTableData = () => { 
    const { column, order } = sortConfig;
    if (column) {
        return [...props.tableData]?.sort((a, b) => {
            const valueA = a[column];
            const valueB = b[column];

            // Handling Date columns
            if (column.includes("Date")) {
                const dateA = getDateValue(valueA);
                const dateB = getDateValue(valueB);
                return order === 'asc' ? dateA - dateB : dateB - dateA;
            }

            // Treat `NA`, undefined, or null as the smallest or largest depending on the order
            const isNAorNullA = valueA === undefined || valueA === null || valueA === 'NA' ;
            const isNAorNullB = valueB === undefined || valueB === null || valueB === 'NA';

            if (isNAorNullA && isNAorNullB) {
                return 0; // If both are NA or null, no sorting needed
            }
            if (isNAorNullA) {
                return order === 'asc' ? -1 : 1; // `NA` should be at the top in ascending, bottom in descending
            }
            if (isNAorNullB) {
                return order === 'asc' ? 1 : -1; // `NA` should be at the top in ascending, bottom in descending
            }

            // If both are numbers, compare numerically
            if (!isNaN(valueA) && !isNaN(valueB)) {
                return order === 'asc' ? valueA - valueB : valueB - valueA;
            }

            // If both are strings, compare alphabetically
            const comparison = valueA.localeCompare(valueB);
            return order === 'asc' ? comparison : -comparison;
        });
    }

    // If no column specified, return the data unchanged
    return order === 'asc' ? props.tableData : props.tableData.slice()?.reverse();
};


    const openEdit = (row) => {
        setOpenEditModal(true);
        setShowBackDrop(true);
        setrowData(row)
    }

    const closeConfirmationModal = () => {
        setOpenEditModal(false);
        setShowBackDrop(false)
    }

    useEffect(() => {
        if (props.capacityAllocation) {
            selectedRows.forEach((ID) => {
                let item = JSON.parse(JSON.stringify(props.tableData)).find(item => item.RequestID === ID);
                const existingItem = props.regionSums.find((mergedItem) => mergedItem.id === item.RequestID);
                if (!existingItem) {
                    props.regionSums.push({
                        region: item.RegionGranted,
                        value: item.PTU_Granted,
                        id: item.RequestID,
                        currentModelKey: item.ModelID,
                    });
                }
            })
            if (sessionStorage.getItem("tab") === "2") {
                const uniqueModelKeysSet = new Set();
                props.regionSums.forEach(item => {
                    uniqueModelKeysSet.add(item.currentModelKey);
                });
                const uniqueModelKeysArray = [...uniqueModelKeysSet];
                uniqueModelKeysArray.forEach((modelKey) => {
                    props.joinDataWithRegionSums(modelKey);
                })
            } else {
                props.joinDataWithRegionSums();
            }
        }
    }, [props.capacityAllocation])

    useEffect(()=> {
        setHeaderLabels(sessionStorage.getItem('selectedMenu')=== 'earlyAccessPreview' ? tableFormat.eaHeaderLabels : tableFormat.headerLabels)
        setKeys(sessionStorage.getItem('selectedMenu')=== 'earlyAccessPreview' ? tableFormat.eaKeys : tableFormat.keys)
    },[])
    const handleRowSelect = (rowIndex, flag) => {
        if(!sessionStorage.getItem('tab') == '3'){
            const shallowCopyValues = previousDatasOfSelectedRows?.find(item => item.RequestID === rowIndex);
            let oldValues = shallowCopyValues ? JSON.parse(JSON.stringify(shallowCopyValues)) : "";
            if (flag) {
                return oldValues ? oldValues : ""
            }
            setSelectedRows((prevSelectedRows) => {
                let updatedSelectedRows;
                if (prevSelectedRows?.includes(rowIndex)) {
                    let changedRow = sessionStorage.getItem("tab") === "2" ? JSON.parse(JSON.stringify(managedTableDataReducer)).find(item => item.RequestID === rowIndex) : JSON.parse(JSON.stringify(classicTableDataReducer)).find(item => item.RequestID === rowIndex);
                    if (changedRow['PTU_Granted'] !== oldValues['PTU_Granted'] || changedRow['ModelID'] !== oldValues['ModelID'] || changedRow['RegionGrantedID'] !== oldValues['RegionGrantedID'] || true) {
                        const currentModelKey = getKeyForCurrentModel(changedRow['ModelName']);
                        const currentModelRegionData = allRegionsData[currentModelKey];
                        const currentRegionData = sessionStorage.getItem("tab") === "2" ? currentModelRegionData.filter((item) => item.Region === changedRow.RegionGranted) : props.regionData;
                        const regionGrantedWithoutPool = changedRow.RegionGranted;
                        if (currentRegionData[0]) {
                            if (currentRegionData[0].Pool) {
                                const currentRegionsPool = currentModelRegionData.filter((item) => item.Pool === currentRegionData[0].Pool);
                                changedRow.RegionGranted = currentRegionsPool[0].Region
                            }
                        }
                        if (sessionStorage.getItem("tab") === "2") {
                            // dispatch(removePtusFromManagedRegion({ modelKey: currentModelKey.toString(), oldObject: changedRow }))
                            const indexToRemove = props.regionSums.findIndex(item =>
                                item.region == regionGrantedWithoutPool &&
                                item.value == changedRow.PTU_Granted &&
                                item.id == changedRow.RequestID &&
                                item.currentModelKey == changedRow.ModelID
                            );
    
                            if (indexToRemove !== -1) {
                                props.regionSums.splice(indexToRemove, 1);
                            }
    
                        } else {
                            dispatch(removePtusFromClassicRegion({ oldObject: changedRow }))
                        }
                        // oldValues.RegionGranted = regionGrantedWithoutPool;
                    }
                    updatedSelectedRows = prevSelectedRows.filter((row) => row !== rowIndex);
                    setSelectedRows(updatedSelectedRows)
                    const newTableData = []
                    if (sessionStorage.getItem("tab") === "2") {
                        managedTableDataReducer?.map(item => {
                            if (item.RequestID === rowIndex) {
                                newTableData.push(oldValues)
                            } else {
                                newTableData.push(item)
                            }
                        })
                    } else {
                        classicTableDataReducer?.map(item => {
                            if (item.RequestID === rowIndex) {
                                newTableData.push(oldValues)
                            } else {
                                newTableData.push(item)
                            }
                        })
                    }
                    if (sessionStorage.getItem('tab') === '2') {
                        dispatch(updateAllManagedTableData(newTableData)); // do updatation for respective tabledata data
                    } else {
                        dispatch(updateAllClassicTableData(newTableData));
                    }
                } else {
                    updatedSelectedRows = [...prevSelectedRows, rowIndex];
                    const oldValue = JSON.parse(JSON.stringify(props.tableData?.find(item => item.RequestID === rowIndex)));
                    if (sessionStorage.getItem("tab") === "2") {
                        // if (props.capacityAllocation) {
                        //     const currentModelKey = oldValue.ModelID;
                        //     const currentModelRegionData = allRegionsData[currentModelKey];
                        //     if (currentModelRegionData) {
                        //         const currentRegionData = currentModelRegionData.filter((item) => item.Region === oldValue.RegionGranted);
                        //         if (currentRegionData[0]) {
                        //             if (currentRegionData[0].Pool) {
                        //                 const currentRegionsPool = currentModelRegionData.filter((item) => item.Pool === currentRegionData[0].Pool);
                        //                 oldValue.RegionGranted = currentRegionsPool[0].Region
                        //             }
                        //         }
                        //         const existingItem = props.regionSums.find((mergedItem) => mergedItem.id === oldValue.RequestID);
                        //         if (!existingItem) {
                        //             props.regionSums.push({
                        //                 region: oldValue.RegionGranted,
                        //                 value: oldValue.PTU_Granted,
                        //                 id: oldValue.RequestID,
                        //                 currentModelKey: oldValue.ModelID,
                        //             });
                        //         }
                        //         props.joinDataWithRegionSums(oldValue.ModelID);
                        //     }
                        // }
                    }
                    setPreviousDatasOfSelectedRows([...previousDatasOfSelectedRows, oldValue])
                    if (flag) {
                        return oldValues
                    }
                }
                props.handleSelectedIndex(updatedSelectedRows);
                return updatedSelectedRows;
            });
        }else{
            setSelectedRows((prevSelectedRows) => {
                let updatedSelectedRows;
                if (prevSelectedRows?.includes(rowIndex)) {
                    let changedRow = JSON.parse(JSON.stringify(EATableDataReducer)).find(item => item.RowID === rowIndex) 
                    updatedSelectedRows = prevSelectedRows.filter((row) => row !== rowIndex);
                    setSelectedRows(updatedSelectedRows)
                } else {
                    updatedSelectedRows = [...prevSelectedRows, rowIndex];
                }
                props.handleSelectedIndex(updatedSelectedRows);
                return updatedSelectedRows;
            });
        }
        
    };

    const handleSelectAll = () => {
        let selectAll = props.selectAll;
        if (selectAll) {
            setSelectedRows([]);
            props.handleSelectedIndex([]);
        } else {
            const oldTableValue = JSON.parse(JSON.stringify(props.tableData));
            setPreviousDatasOfSelectedRows([...oldTableValue]);
            if(sessionStorage.getItem('tab') == '3'){
                setSelectedRows(props.tableData.map(item => item.RowID));
                props.handleSelectedIndex(props.tableData.map(item => item.RowID));
            }else{
                setSelectedRows(props.tableData.map(item => item.RequestID));
                props.handleSelectedIndex(props.tableData.map(item => item.RequestID));
            }
           
        }
        props.setSelectAllChange(!selectAll);
    };
    const handletableInputChange = (event, rowIndex, label, row) => {
        setChangedModel(!changedModel);
        props.handletableChange(event, rowIndex, label, row);
    };

    function formatDateForDefaultDateValue(dateString) {
        // Assuming the dateString is in the 'MM/DD/YYYY' format
        // Convert it to the 'YYYY-MM-DD' format required by the date input element
        const parts = dateString.split('/');
        if (parts.length === 3) {
            const [month, day, year] = parts;
            return `${year}-${month}-${day}`;
        }
        return ''; // Return an empty string if the date format is not as expected
    }
 
    const [hasContributor, setHasContributor] = useState(false);
  
    function getKeyForCurrentModel(curentModel) {
        for (const option of modelOptions) {
            if (option.Value == curentModel) return option.Key;
        }
        return 3;
    }

    function getKeyForCurrentModelIndex(curentModelKey) {
        let index = 0;
        for (const option of modelOptions) {
            if (option.Key == curentModelKey) return index;
        }
        return index;
    }


    const setInputField = (rowIndex, label, row, currentModel) => {
        let index = label !== 'Tag' ? props.tableData?.findIndex(item => item.RequestID === rowIndex) : 
        sessionStorage.getItem('tab') === '2' ? managedTableDataReducer?.findIndex(item => item.RequestID === rowIndex) : 
        classicTableDataReducer?.findIndex(item => item.RequestID === rowIndex)  ;
        let currentModelRegionData = [];
        if (sessionStorage.getItem('tab') === '2') {
            let selectedIndex = props.tableData?.filter(item => item.RequestID === rowIndex);
            const currentModelKey = getKeyForCurrentModel(selectedIndex[0].ModelName);
            const currentModelIndex = getKeyForCurrentModelIndex(currentModelKey);
            currentModelRegionData = allRegionsData[currentModelKey];
        } else {
            currentModelRegionData = props.regionData;
        }

        if (label === 'RegionGranted') {
            return (
                <div key={rowIndex} className="input-container master-table-input">
                    <select
                        className="select-container-table"
                        style={{ color: 'black' }}
                        onChange={(event) => { handletableInputChange(event, rowIndex, 'RegionGrantedID', label); props.checkLimit(event, rowIndex, label) }}
                        defaultValue={props.tableData[index]['RegionGrantedID']}
                        id={label === 'RegionGranted' ? `RegionGranted_${rowIndex}` : undefined}
                    >
                        {regionGrantedOptions?.map((item, index) => {
                            const regionDataItem = currentModelRegionData.find((region) => region.Region === item.Value);
                            
                            if (index === 0 || (sessionStorage.getItem('tab') == '2' ? regionDataItem : true)) {
                                return (
                                    <option style={{ color: 'black' }} key={item.Key} value={item.Key}>
                                        {item.Value}
                                    </option>
                                );
                            }


                        })
                        }
                    </select>
                </div>
            )
        }
        if (label === 'Tag') {
            return (
                <div key={rowIndex} className="" >{
                    sessionStorage.getItem('tab') === "2" && (
                        <MultiSelectCheckbox options={PTUManagedTriageTagsReducer} selectedOptions={managedTableDataReducer[index]['Tag'] !== "" ? managedTableDataReducer[index]['Tag']?.split(',') : ""} onChange={(e) => handletableInputChange(e, rowIndex, 'Tag', label)} />

                    )
                }
                    {
                        sessionStorage.getItem('tab') === "1" && (
                            <MultiSelectCheckbox options={PTUManagedTriageTagsReducer} selectedOptions={classicTableDataReducer[index]['Tag'] !== "" ? classicTableDataReducer[index]['Tag']?.split(',') : ""} onChange={(e) => handletableInputChange(e, rowIndex, 'Tag', label)} />

                        )
                    }

                </div>
            )
        }
        else if (label === 'PTU_Granted') {
            return (
                <div key={rowIndex} className="input-container master-table-input master-ptu">
                    <div>
                        <input
                            type="number"
                            className="custom-input-table"
                            style={{ outline: 'none', width: 150 }}
                            defaultValue={props.tableData[index][label] || ''}
                            id={label === 'PTU_Granted' ? `PTU_Granted_${rowIndex}` : undefined}
                            onChange={(event) => {
                                handletableInputChange(event, rowIndex, label, row); props.handleBlur(event, rowIndex, label, row);
                            }
                            }
                            min={0}
                        />
                        {errorRow === rowIndex && (
                            <>
                                {errorLabel ? (
                                    <div
                                        style={{ color: "red", fontSize: "9px" }}
                                    >
                                        {errorLabel}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}


                    </div>
                </div>
            )
        }
        else if (label === 'DateApproved' || label === 'DeprovisionDate') {
            return (
                <div key={rowIndex} className="input-container master-table-input">
                    <input
                        type="date"
                        className="custom-input-table"
                        placeholder="Enter Date Approved"
                        onKeyDown={(event) => event.preventDefault()}
                        defaultValue={formatDateForDefaultDateValue(props.tableData[index][label])}
                        onChange={(event) => { handletableInputChange(event, rowIndex, label) }}
                    />
                </div>
            );
        }
        else if (label === 'RequestGrantStatus') {
            return (
                <div key={rowIndex} className="input-container">
                    <select
                        className="select-container-table"
                        onChange={(event) => { handletableInputChange(event, rowIndex, 'RequestGrantStatusID') }}
                        defaultValue={props.tableData[index]['RequestGrantStatusID']}
                        style={{ color: 'black' }}
                    >
                        {regionGrantedStatusOptions?.map((item, index) => (
                            <option key={index} style={{ color: 'black' }} value={item["Key"]}>{item["Value"]}</option>
                        ))}
                    </select>
                </div>
            )
        }
        else if (label === 'ModelName') {
            return (
                <div key={rowIndex} className="input-container master-table-input ">
                    <select
                        className="select-container-table"
                        onChange={(event) => { handletableInputChange(event, rowIndex, 'ModelID'); }}
                        defaultValue={props.tableData[index]['ModelID']}
                        style={{ color: 'black' }}
                    >
                        <option value="-1" disabled="disabled">-- Select Model --</option>
                        {modelOptions?.map((item, index) => (
                            <option key={index} disabled={item['Key'] === '1' || item['Key'] === '2'} style={{ color: 'black' }} value={item["Key"]}>{item["Value"]}</option>
                        ))}
                    </select>
                </div>
            )
        }
        else if (label === 'ModelName') {
            return (
                <div key={rowIndex} className="input-container master-table-input ">
                    <select
                        className="select-container-table"
                        onChange={(event) => { handletableInputChange(event, rowIndex, 'ModelID'); }}
                        defaultValue={props.tableData[index]['ModelID']}
                        style={{ color: 'black' }}
                    >
                        <option value="-1" disabled="disabled">-- Select Tag --</option>
                        {tagsOptions?.map((item, index) => (
                            <option key={index} disabled={item['ID'] === 1 || item['ID'] === 2} style={{ color: 'black' }} value={item["ID"]}>{item["Name"]}</option>
                        ))}
                    </select>
                </div>
            )
        }
        else {
            return (
                <div key={rowIndex} className="input-container master-table-input">
                    <input
                        type="text"
                        className="custom-input-table"
                        placeholder={`Enter ${label}`}
                        onChange={(event) => { handletableInputChange(event, rowIndex, label) }}
                    />
                </div>
            )
        }
    }

    useEffect(() => {
        setHasContributor(sessionStorage.getItem("ActiveRole")?.includes('Contributor'));
        if (sessionStorage.getItem('tab') === '2') {
            setRegionGrantedOptions(AllManageSubmissionsRegionGrantedOptions);
            setTagsOptions(PTUManagedTriageTagsReducer);
        } else {
            setRegionGrantedOptions(classicRegionGrantedOptions);
        }
    })

    React.useEffect(() => {
        initiateDropdowns();
    }, [changedModel]);

    const initiateDropdowns = async () => {
        if (sessionStorage.getItem('tab') === '2') {
            setRegionGrantedOptions(AllManageSubmissionsRegionGrantedOptions);
        } else {
            setRegionGrantedOptions(classicRegionGrantedOptions);
        }
    }

    return (
        <> <Draggable  showScroll={true}>
            <table className='auto-width master-table ' style={{ borderSpacing: '0px'}}>
                <thead>
                    <tr style={tableheader}>
                        <th className='tableTD' style={{ maxWidth: '100px', position: 'sticky !important', top: 0, zIndex: 1 }}>
                            <input
                                type="checkbox"
                                checked={props.selectAll}
                                onChange={handleSelectAll}
                            />
                        </th>
                        { headerLabels.length > 0 && headerLabels.map((label, index) => (
                            <th key={index} style={{ ...headerStyle, position: 'sticky !important', top: 0, zIndex: 1 }}
                                onClick={() => {
                                    const keys = sessionStorage.getItem('tab') === '3' ? tableFormat.eaKeys : tableFormat.keys;
                                    handleSort(keys[index]);
                                }}>
                                {label === 'Request Granted Status' ? 'Request Granted' : label}
                                {sortConfig.column === (sessionStorage.getItem('tab') === '3' ? tableFormat.eaKeys[index] : tableFormat.keys[index]) && (
                                    <span style={{ marginLeft: '10px' }}>
                                {sortConfig.order === 'asc' ? '▲' : '▼'}
                                </span>
                            )}
                            </th>
                        ))}
                    </tr>
                </thead>
                {!props.tableLoader &&
                    <tbody>
                        {sortedTableData()?.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                style={{
                                    backgroundColor: selectedRows?.includes(rowIndex) || selectedRows?.includes(row.RowID) ? '#f6f6f6' : '#f6f6f6',
                                }}
                            >
                                <td className='tableTD' style={{ backgroundColor: selectedRows.includes(row.RequestID) || selectedRows?.includes(row.RowID) ? '#ccd8e0' : '#f6f6f6' }}>
                                    <input
                                        type="checkbox"
                                        className={row['Tag'].includes("Risk") ? 'red-checkbox' : row['Tag'].includes("Missing Info") ? "yellow-checkbox" : ""}
                                        checked={props.selectedIndex?.includes(row.RequestID) || selectedRows?.includes(row.RowID)}
                                        onChange={() => { handleRowSelect(sessionStorage.getItem('tab') == '3' ? row.RowID : row.RequestID, false); if (props.rowErrors !== '') { props.rowErrors[rowIndex] = '' } }}
                                    />
                                </td>


                                {keys.length > 0 && keys.map((label, columnIndex) =>

                                (

                                    <td
                                        key={columnIndex}
                                        style={{
                                            ...bodyStyle,
                                            backgroundColor: selectedRows.includes(row.RequestID) || selectedRows?.includes(row.RowID) ? '#ccd8e0' : '',
                                            maxWidth: (["ModelName", "RegionGranted", "Region", "DateApproved", "RequestGrantStatus", "PTU_Granted", "SubscriptionID","GetHelpID"].includes(label) || label.includes("Date")) ? "fit-content" : (label === 'Tag' || label === "Notes" ?  selectedRows && props.capacityAllocation? "12vw" : "10vw" : "2vw"),
                                            minWidth: (
                                                label === 'Tag' || label === 'Notes'
                                                  ? (selectedRows && props.capacityAllocation ? '10vw' : '')
                                                  : (label === 'RegionRequested' ? '10vw' : '')
                                              ),
                                           
                                            ...(label === 'CustomerName' ? (hasContributor ? customerColumn : { ...customerColumn, ...basicCustomer }) : {})
                                        }}
                                        
                                        
                                        title={
                                            `ID: "${row['GetHelpID'] && row['GetHelpID'] !== -1 && row['GetHelpID'] !== '-1' ? row['GetHelpID'] : 'NA'}"\nTag :"${row['Tag']}"\nCustomer Name: "${row['CustomerName'] && row['CustomerName'] !== -1 && row['CustomerName'] !== '-1' ? row['CustomerName'] : 'NA'}"\nSubscription ID: "${row['SubscriptionID'] && row['SubscriptionID'] !== -1 && row['SubscriptionID'] !== '-1' ? row['SubscriptionID'] : 'NA'}"\n${label !== 'GetHelpID' && label !== 'CustomerName' && label !== 'SubscriptionID' && label !== 'Tag' ? `${label} : ${row[label] && row[label] !== -1 && row[label] !== '-1' ? `"${row[label]}"` : 'NA'}` : ''}`}
                                    >

                                        {((label === 'DateApproved' || label === 'RequestGrantStatus' || label === 'RegionGranted' || label === 'PTU_Granted' || label === 'Notes' || label === 'Tag' || (label === 'ModelName' && sessionStorage.getItem('tab') === '2'))
                                            && (props.capacityAllocation)) ? (
                                            selectedRows.includes(row.RequestID) || selectedRows.includes(row.RowID)?
                                                setInputField(row.RequestID, label, row.RegionGranted, row.ModelName)
                                                : (row[label] && row[label] !== -1 && row[label] !== '-1' ? <span onClick={() => {
                                                    if (label === 'CustomerName') {
                                                        openEdit(row);
                                                    }
                                                }}>{row[label]}</span> : <span onClick={() => {
                                                    if (label === 'CustomerName' && hasContributor) {
                                                        openEdit(row);
                                                    }
                                                }}>NA</span>)
                                        ) : (
                                            (row[label] && row[label] !== -1 && row[label] !== '-1' ? <span 
                                                className={
                                                    label === "GetHelpID" && row['Tag'].includes("UAT Complete") ? "green-text" :
                                                    label === "PTU_Granted" && row['Tag'].includes("ready to provision") ? "blue-text" :
                                                    label === "PTU_Granted" && row['Tag'].includes("need scaling") ? "pink-text" : 
                                                    label === "SubscriptionID" && row['Tag'].includes("SUB ID Change") ? "orange-text" : 
                                                    label === "RegionRequested" && row['Tag'].includes("Region Change") ? "orange-text" : 
                                                    label === "ModelName" && row['Tag'].includes("Model Change") ? "orange-text" : 
                                                    label === "PTU_Requested" && row['Tag'].includes("PTUs Change") ? "orange-text" : ""}
                                                onClick={() => {
                                                if (label === 'CustomerName' && hasContributor) {
                                                    const oldData = handleRowSelect(sessionStorage.getItem('tab') == '3' ? row.RowID :row.RequestID, true)
                                                    openEdit(oldData ? oldData : row);
                                                }
                                            }}>{row[label]}</span> : <span onClick={() => {
                                                if (label === 'CustomerName' && hasContributor) {
                                                    openEdit(row);
                                                }
                                            }}>NA</span>)
                                        )}
                                        {props.rowErrors[rowIndex] && props.errorLabel === label ? (
                                            <div className="error-message" style={{ color: 'red', fontSize: '8px', whiteSpace: 'initial', lineHeight: '12px' }}>
                                                {props.rowErrors[rowIndex]}
                                            </div>
                                        ) : ''}

                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
        </Draggable>

            {props.tableLoader &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }

            {showBackDrop && <div className="modal-backdrop" onClick={closeConfirmationModal}></div>}
            {openEditModal ? <Detail
                closeConfirmationModal={closeConfirmationModal}
                data={rowData}
                regionData={props.regionData}
                regionGrantedOptions={regionGrantedOptions}
                regionGrantedStatusOptions={regionGrantedStatusOptions}
                modelOptions={sessionStorage.getItem('tab') === '2' ? modelOptions : classicModelNameOptions}
                subscriptionOptions={[]}
                gbbRegionOptions={gbbRegionOptions}
                requestStateOption={requestStateOption}
                handletableInputChange={handletableInputChange}
                PTUManagedTriageTagsReducer={PTUManagedTriageTagsReducer}
            ></Detail> : ''}

        </>
    );
};

const headerStyle = {
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    padding: '10px',
    maxWidth: 'auto'
};

const bodyStyle = {
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    padding: '10px',
    minWidth: "4vw"
};
const tableheader = {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex:1
}

const customerColumn = {
    maxWidth: '12vw',
    color: '#005a9e',
    textDecoration: 'underline',
    cursor: 'pointer'
}
const basicCustomer = {
    cursor: 'default',
    textDecoration: 'none',
    color: 'none'
  
}
export default AddComponent;