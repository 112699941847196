import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/navbar/NavBar';
import "./errorPage.css";
import error404Img from '../../../assets/error404Img.png';
import mailIcon from '../../../assets/mailIcon.png';

const ExceptionHandled = () => {
  const navigate = useNavigate();

  const checkUrl = async () => {
    try {
      if (!sessionStorage.getItem('userToken')) {
        if (!sessionStorage.getItem('href')) {
          sessionStorage.setItem("href", window.location.href)
          navigate('/')
        }
      }
    }
    catch (error) {
      if (!sessionStorage.getItem('href')) {
        sessionStorage.setItem("href", window.location.href)
        navigate('/')
      }
    }
  }

  return (
    <>
      <NavBar checkUrl={checkUrl}></NavBar>
      <div className="outer-div">
        <div className="left-div">
          <div className="head">
            <span>Oops! Something’s Wrong Here….</span>
          </div>
          <div className="sub">
            <p>You don't seem to have access to this application yet. You can write to Priya Devakumar to request access to this site.</p>
          </div>
          <div className="mail-div">
            <div className="mailIcon">
              <img src={mailIcon} alt="mailIcon" />
            </div>
            <div className="emailId">
              <span>pdevakumar@microsoft.com</span>
            </div>
          </div>
        </div>
        <div className="right-div">
          <div className="img-outer">
            <img src={error404Img} alt="error404" />
          </div>
        </div>
      </div>
    </>

  );
};

export default ExceptionHandled;
