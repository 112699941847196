import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Reactselect from "react-select";
import DatePicker from "react-datepicker";
import baseConfig from "../../../config/config";
import "react-datepicker/dist/react-datepicker.css";
import "./earlyAceess.css";
import "../mainPage/mainPage.css";
import "../../components/PTUAvailable/ptuAvailable.css";
import { components } from "react-select";
import { useSelector } from "react-redux";
import crossImg from "./../../../assets/cross.png";
import Draggable from "../../components/tableComponent/tableComponent/Draggable";
const CustomValueContainer = ({ children, ...props }) => {
  const selectedValues = props.getValue();
  const { selectProps, hasValue } = props;
  const handleRemove = (removedValue) => {
    const newValue = selectedValues.filter(
      (val) => val.value !== removedValue.value
    );
    selectProps.onChange(newValue, { action: "remove-value", removedValue });
  };

  return (
    <components.ValueContainer {...props}>
      <div className="drag-tag" onClick={props.selectProps.onMenuOpen}>
        <Draggable showScroll={false}>
          {!hasValue && !props.selectProps.inputValue && (
            <div className="custom-placeholder" style={{ color: "#888888" }}>
              {selectProps.placeholder}
            </div>
          )}
          {selectedValues.map((val, index) => (
            <span
              className="filter-value"
              key={val.value}
              style={{ marginRight: "8px" }}
            >
              {val.label} {"  "}
              <span className="multiselect-remove">
                <img
                  src={crossImg}
                  onClick={() => handleRemove(val)}
                  width={"13px"}
                />
              </span>
            </span>
          ))}
        </Draggable>
      </div>
    </components.ValueContainer>
  );
};

const EAModelTable = () => {

  const handleDateSelect = (date, field) => {
    setEditedData({
      ...editedData,
      [field]: date
    });
  };

  const handleEditClick = (model) => {
    setEditingRow(model.RowID);
    setModel(model);
    setEditedData({
      EMADate: model.EMADate ? parseDateString(model.EMADate) : '',
      GADate: model.GADate ? parseDateString(model.GADate) : '',
    });
  };


  const reactSelectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: "Segoe UI",
      fontSize: "13px",
      fontWeight: "600",
      minHeight: "20px",
      height: "32.5px",
      width: "18.6vw",
      marginBotton: 5,
      border: "1px solid #888888",
      borderRadius: "0px",
      zIndex: 1,
      boxShadow: state.isFocused ? "0px 0px 0px black" : "none",
      "&:hover": {
        border: "1px solid #888888",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
      // whiteSpace: 'nowrap',
      flexWrap: "unset",
      zIndex: 10002,
      fontFamily:
        'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
      fontSize: "5px",
      fontWeight: "600",
      height: "32px",
      padding: "0px 5px 0px 5px",
      width: "18.5vw",
    }),
    multiValue: (base) => ({
      ...base,
      flex: "1 0 auto",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      boxShadow: state.isFocused ? "0px 0px 4px black" : "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 7,
      border: "3px solidrgb(92, 92, 92)"
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      top: "6px",
      left: "280px",
    }),
  };

  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const eaData = useSelector((state) => state.eaData.eaData);
  const regionOptions =
    eaData?.Region?.map((item) => ({
      value: item.Key,
      label: item.Value,
    })) || [];

  const parseDateString = (dateString) => {
    if (!dateString) return null;

    const parts = dateString.split('-');
    if (parts.length === 3 && parts[0].length === 2) {
      const [month, day, year] = parts;
      const date = new Date(`${year}-${month}-${day}`);
      return isNaN(date.getTime()) ? null : date;
    }
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };


  const formatDisplayDate = (dateStr) => {
    if (!dateStr) return 'N/A';
    const date = parseDateString(dateStr);
    if (!date) return 'N/A';

    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const formatDateForAPI = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) return '';
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const handleSaveClick = (RowID) => {
    const config = {
      headers: {
        "content-type": "application/json",
        serviceid: sessionStorage.getItem("userToken")
      },
    };
    const url = baseConfig.apiBaseUrl + "/EAModel/Update";
    axios
      .post(
        url,
        {
          RowID,
          emadate: formatDateForAPI(editedData.EMADate),
          gadate: formatDateForAPI(editedData.GADate),
        },
        config
      )
      .then(() => {
        alert("Updated successfully!");
        setModels(
          models.map((model) =>
            model.RowID === RowID
              ? {
                ...model,
                EMADate: formatDateForAPI(editedData.EMADate),
                GADate: formatDateForAPI(editedData.GADate),
              }
              : model
          )
        );
        setModel("")
        setEditingRow(null);
      })
      .catch(() => alert("Failed to update."));
  };

  const handleCancelClick = () => {
    setEditingRow(null);
    setModel("")
    setEditedData({});
  };


  const filterModelTable = (options) => {
    const commaSeparatedValues = options.map((item) => item.value).join(",");
    const url =
      baseConfig.apiBaseUrl +
      "/EARequest/ListEAModel?filter=" +
      commaSeparatedValues;

    axios
      .post(url, null, {
        headers: {
          "Content-Type": "application/json",
          serviceid: sessionStorage.getItem("userToken")
        },
      })
      .then((response) => setModels(response.data))
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div style={{ maxWidth: "50%", textAlign: "center", marginLeft: "25%" }}>
        <div style={{ maxWidth: "38%", marginBottom: 5 }}>
          <Reactselect
            isClearable
            isMulti
            id="demo-select-small"
            name="Choose Region"
            placeholder="Choose Region"
            styles={reactSelectCustomStyles}
            options={regionOptions?.map((item) => ({
              value: item["label"],
              label: item["label"],
            }))}
            onChange={(selectedOption) => {
              filterModelTable(selectedOption);
            }}
            classNamePrefix="react-select"
            components={{ ValueContainer: CustomValueContainer }}
          />
        </div>
        <table className="ptu-table" id="modelTable">
          <thead>
            <tr>
              <th>Region</th>
              <th>Model Name</th>
              <th>Version</th>
              <th style={{ padding: "8px" }}>EMA Date</th>
              <th>GA Date</th>
              <th
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {models.length > 0 &&
              models.map((model) => (
                <tr key={model.RowID}>
                  <td>{model.Region}</td>
                  <td>{model.ModelName}</td>
                  <td>{model.Version}</td>
                  <td style={{ padding: "8px", textAlign: "center" }}>
                    {editingRow === model.RowID ? (
                      <>
                        <style>
                     {`  
                        .custom-datepicker {
                          z-index: 1050;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          position: absolute;
                        }

                        .custom-datepicker .react-datepicker__day--selected {
                          color: white;
                        }

                        .custom-datepicker .react-datepicker__day:hover {
                          background-color: #f0f0f0;
                        }

                        .custom-datepicker .react-datepicker__month {
                          margin-top: 5px;
                        }

                        .react-datepicker__popper {
                          z-index: 1060 !important;
                        }

                        .date-picker-wrapper {
                          width: 90%;
                          display: flex;
                          justify-content: center;
                        }
                    `}
                        </style>
                        <div className="date-picker-wrapper">
                          <DatePicker
                            selected={editedData.EMADate}
                            onChange={(date) => handleDateSelect(date, 'EMADate')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select date"
                            popperPlacement="bottom"
                            autoComplete="off"
                            strictParsing={true}
                            popperClassName="custom-datepicker"
                            customInput={
                              <input
                                style={{
                                  width: "100%",
                                  padding: "6px",
                                  border: "1px solid #ccc",
                                  borderRadius: "2px",
                                  textAlign: "center",
                                  color: "#333333"
                                }}
                              />
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <span style={{ color: "#333333" }}>{formatDisplayDate(model.EMADate)}</span>
                    )}
                  </td>
                  <td style={{ padding: "8px", textAlign: "center" }}>
                    {editingRow === model.RowID ? (
                      <div className="date-picker-wrapper">
                        <DatePicker
                          selected={editedData.GADate}
                          onChange={(date) => handleDateSelect(date, 'GADate')}
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                          strictParsing={true}
                          placeholderText="Select date"
                          popperPlacement="bottom"
                          popperClassName="custom-datepicker"
                          customInput={
                            <input
                              style={{
                                width: "100%",
                                padding: "6px",
                                border: "1px solid #ccc",
                                borderRadius: "2px",
                                textAlign: "center",
                                 color: "#333333"
                              }}
                            />
                          }
                        />
                      </div>
                    ) : (
                      <span style={{ color: "#333333" }}>{formatDisplayDate(model.GADate)}</span>
                    )}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {editingRow !== model.RowID && (
                      <div
                        style={{
                          justifyContent: "left",
                          display: "flex",
                          alignItems: "left",
                          marginLeft: 10,
                        }}
                      >
                        <i
                          onClick={() => {
                            handleEditClick(model);
                          }}
                          className="fa fa-edit icon"
                          style={{ color: "#4B81FF", marginRight: "10px" }}
                        ></i>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            {models.length == 0 && (
              <tr>
                <td colSpan={5}>Please select region</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        {model && (
          <div
            style={{
              justifyContent: "right",
              display: "flex",
              alignItems: "left",
              marginLeft: 10,
            }}
          >
            <button
              style={{
                padding: "15px",
                height: "5vh",
                backgroundColor: "#4b81ff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                marginLeft: "auto",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handleSaveClick(model.RowID);
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                handleCancelClick();
              }}
              style={{
                padding: "15px",
                height: "5vh",
                backgroundColor: "#6f7173",
                color: "white",
                border: "none",
                borderRadius: "5px",
                marginLeft: "5px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EAModelTable;
