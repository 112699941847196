import React, { useEffect, useState } from 'react';
import "./mainPage.css";
import NavBar from '../../components/navbar/NavBar';
import AllSubmissions from '../allSubmission/allSubmission';
import EarlyAccess from '../earlyAccessPreview/earlyAccess'
import { useNavigate, useParams } from "react-router-dom";
import CapacityRoadMap from '../capacityRoadMap/capacityRoadMap'
import * as XLSX from 'xlsx';
import UploadExcel from '../uploadExcel/uploadExcel';
import uploadImg from './../../../assets/upload.png';
import downloadImg from './../../../assets/downloadTemplate.png';
import axios from 'axios';
import baseConfig from '../../../config/config';
import AllManageSubmissions from '../manageAllSubmission/allManageSubmission';
import { useSelector, useDispatch } from 'react-redux';

const tabsData = [
    { label: 'Capacity Roadmap', content: <CapacityRoadMap /> },
    { label: 'PTU Classic Triage', content: <AllSubmissions /> },
    { label: 'PTU Managed Triage', content: <AllManageSubmissions /> },
    { label: 'Early Model Access', content: <EarlyAccess /> },
];

const MainPage = () => {
    const navigate = useNavigate();
    const { filters } = useParams();
    const [activeTab, setActiveTab] = useState(sessionStorage.getItem("tab") || 0);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [showEarlyAccess, setShowEarlyAccess] = useState(false);
    const [successfulLogin, setSuccessfulLogin] = useState(false);
    const [liveData, setLiveData] = React.useState([]);
    const [modelWiseData, setModalWiseData] = React.useState([]);
    const modalTypeData = useSelector(store => store.modalTypeData.modalTypeData !== undefined ? store.modalTypeData.modalTypeData : []);
    const [gpt35turboData, setGpt35turbo] = React.useState([]);
    const [gpt35turbo16kData, setGpt35turbo16k] = React.useState([]);
    const [gpt4Data, setGpt4] = React.useState([]);
    const [gpt432kData, setGpt432k] = React.useState([]);
    const ptusData = useSelector(store => store.regionDataClassic.regionDataClassic !== undefined ? store.regionDataClassic.regionDataClassic : []);


    const [visibleTabs, setVisibleTabs] = useState([true, true, true, false]);
    const [openPTU, setOpenPTU] = useState(false);
    const [openUploadPopup, setOpenUploadPopup] = useState(false)
    const [isContributor, setIsContributor] = useState(false)

    const dispatch = useDispatch()
    function showToast(message) {
        const toaster = document.createElement('div');
        toaster.className = 'toaster danger-toaster';
        toaster.style.position = 'fixed';
        toaster.style.top = '5%';
        toaster.style.left = '50%';
        toaster.style.transform = 'translateX(-50%)';

        toaster.style.height = 'fit-content'; // Adjust the height as needed
        toaster.style.maxWidth = '300px';

        toaster.innerText = message;



        document.body.appendChild(toaster);
        setTimeout(() => {
            toaster.style.display = 'none'; // or toaster.remove(); to completely remove it from the DOM
        }, 5000);
    }
    const openPtu = () => {
        setOpenPTU(!openPTU);
    };
    const handleTabClick = (index) => {
        if (!visibleTabs[index]) return;
        const url = new URL(window.location.href);
        if (index === 1) {
            const ptuFilter = JSON.parse(sessionStorage.getItem('ptuFilter'))
            if (ptuFilter !== null) {
                var tempvar_ptuFilter = JSON.stringify(ptuFilter.filter((obj) => obj.property !== "ptuTriage"))
                tempvar_ptuFilter = encodeURI(tempvar_ptuFilter)
                // url.searchParams.set("key", tempvar_ptuFilter);
            } else {
                url.pathname = `/Home`
                url.search = ''
            }
        } else if (index === 2) {
            const ptuManageFilter = JSON.parse(sessionStorage.getItem('ptuManageFilter'))
            if (ptuManageFilter !== null) {
                var tempvar = JSON.stringify(ptuManageFilter.filter((obj) => obj.property !== "ptuManageTriage"))
                tempvar = encodeURI(tempvar)
                // url.searchParams.set("key", tempvar);
            } else {
                url.pathname = `/Home`
                url.search = ''
            }
        } else {
            url.pathname = `/Home`
        }
        setActiveTab(index);
        sessionStorage.setItem('tab', index)
        window.history.pushState({}, '', url.href);
    };
    const openPopup = () => {
        setIsOpen(!isOpen);
    };

    const setLiveTableData = (val) => {
        setLiveData(val)
    }

    const setModalWiseTableData = (val) => {
        setModalWiseData(val)
    }



    // Get the URL
    var url = new URL(window.location.href);

    // Extract the filter array from the URL
    var filterArray = extractFilterArray(url);

    function extractFilterArray(url) {
        let currentURL = new URL(url)
        let filter = null;
        if (currentURL.searchParams.has('key')) {
            filter = currentURL.searchParams.get("key")
            filter = decodeURI(filter)
            filter = filter.replace("?key=", "/")
            return JSON.parse(filter);

        }

        else {
            return JSON.stringify([]);
        }
    }

    const checkUrl = async () => {
        try {
            if (sessionStorage.getItem('userToken')) {
                setSuccessfulLogin(true)
            }
            else {
                setSuccessfulLogin(false);
                if (!sessionStorage.getItem('href')) {
                    sessionStorage.setItem("href", window.location.href)
                    navigate('/')
                }
            }
        }
        catch (error) {
            setSuccessfulLogin(false);
            if (!sessionStorage.getItem('href')) {
                sessionStorage.setItem("href", window.location.href)
                navigate('/')
            }
        }
    }

    const downloadTemplate = async () => {
        const url = sessionStorage.getItem('tab') === '1' ? '?TemplateType=ClassicTemplate' : sessionStorage.getItem('tab') === '2' ? '?TemplateType=ManagedTemplate' : '?TemplateType=EARequestTemplate'
        const config = {
            headers: {
                'content-type': 'application/json',
                "serviceid": sessionStorage.getItem("userToken")
            }
        };
        axios.get(baseConfig.apiBaseUrl + '/Utility/Download' + url, config)
            .then(response => {
                if (response.status === 200) {
                    try {
                        window.open(response.data, '_blank');
                        window.URL.revokeObjectURL(response.data);
                    } catch (error) {
                        console.error('Error downloading Excel file:', error);
                    }
                }

            })
            .catch(function (error) {
                console.error(error);

                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            });
    };


    const exportToExcel = (tableIndex) => {

        const workbook = XLSX.utils.book_new();
        const link = document.createElement('a');
        const combinedData = [];
        const addTotalRow = (tableData, isModelType) => {
            const totalRow = [];
            if (tableData.length > 0) {
                let tableKey = Object.keys(tableData[0]).filter(key => key !== 'RegionID');
                tableKey.forEach((label, index) => {
                    if (label === "SellableETAmonth" || label == 'GBB Region' || label === 'Region' || label === 'GBBRegion') {
                        totalRow.push('');
                    } else {
                        const sum = tableData.reduce((acc, item) => {
                            if (JSON.stringify(item[label])?.includes('/')) {
                                return 0;
                            } else {
                                if (!isModelType) {
                                    return acc + parseInt(item[label])
                                }
                                else if ((item.Region.includes("Pool A") || item.Region.includes("Pool B")) || !item.Region.includes('haspool')) {
                                    return acc + parseInt(item[label])
                                } else {
                                    return acc + 0
                                }
                            }
                        }, 0);

                        if (!tableKey.includes("ID")) {
                            totalRow[0] = "Total";
                        }
                        if (index === 0) {
                            totalRow.push("Total");
                        } else {
                            totalRow.push(sum)
                        }
                    }
                });
            }
            if (!isModelType && !Object.keys(tableData[0]).some(key => key.includes("Budgeted"))) {
                totalRow.splice(1, 1)
            }
            return totalRow;
        };
        if ((tableIndex === 0 || tableIndex === -1) && liveData.length > 0) {
            link.download = 'Live Capacity Roadmap.xlsx';
            combinedData.push(['Live Capacity Roadmap']);
            const headerRow = Object.keys(liveData[0]);
            headerRow.slice(0, 1)


            const otherData = liveData.filter(obj => !obj.Region.includes('Pool'));
            const poolAData = liveData.filter(obj => obj.Region.includes('Pool A'));
            const poolBData = liveData.filter(obj => obj.Region.includes('Pool B'));

            const poolAField = { Region: 'Pool A' };
            const poolBField = { Region: 'Pool B' };
            const modifiedLiveData = [
                ...otherData,
                poolAField,
                ...poolAData.map(obj => ({ ...obj, Region: obj.Region.replace('Pool A : ', '') })),
                poolBField,
                ...poolBData.map(obj => ({ ...obj, Region: obj.Region.replace('Pool B : ', '') }))
            ];

            const data = [headerRow, ...modifiedLiveData.map(item => Object.values(item))];
            let modifiedData = data.map(sublist => {
                if (sublist[0].includes("Pool")) {
                    return [sublist[0]]
                }
                return [...sublist.slice(2)]
            });
            combinedData.push(...modifiedData);


            combinedData.push(addTotalRow(liveData, false));
            combinedData.push([]);



        }
        if ((tableIndex === 1 || tableIndex === -1) && ptusData.length > 0) {
            link.download = 'PTU Classic.xlsx';
            combinedData.push(['PTU Classic']);
            const headerRow2 = Object.keys(ptusData[0]);
            const data2 = [headerRow2, ...ptusData.map(item => Object.values(item))];
            combinedData.push(...data2);
            combinedData.push(addTotalRow(ptusData, false));
            combinedData.push([]);


        } if (tableIndex > 1 || tableIndex === -1) {

            modalTypeData.map((item, index) => {
                if (tableIndex === index + 2 || tableIndex === -1) {
                    link.download = `${item?.modalName}.xlsx`;
                    combinedData.push([item?.modalName]);

                    const headerRowAll = Object.keys(item.data[0]);
                    const modifiedHeaderRow = item.modalName !== modalTypeData.slice(-1)[0]?.modalName ? headerRowAll.filter(item => item !== "Pool") : headerRowAll.filter(item => item !== "Pool" && item !== "ColorID" && item !== "GBBRegion");
                    const itemData = item.data.map(obj => {
                        if (item.modalName !== modalTypeData.slice(-1)[0]?.modalName) {
                            const { Pool, ...rest } = obj;
                            return Object.values(rest);
                        }
                        const { Pool, ColorID, GBBRegion, ...rest } = obj;
                        return Object.values(rest);
                    });
                    const data = [modifiedHeaderRow, ...itemData];
                    combinedData.push(...data);
                    const totalData = item.data.map(obj => {
                        const { Pool, ...rest } = obj;
                        const newObj = {};
                        for (const key in rest) {
                            newObj[key] = rest[key];
                        }
                        newObj.Region += (obj.Pool ? "haspool" : "");
                        return newObj;
                    });
                    if (item.modalName !== modalTypeData.slice(-1)[0]?.modalName) combinedData.push(addTotalRow(totalData, true));
                    combinedData.push([]);

                }

            })

        }

        const worksheet = XLSX.utils.aoa_to_sheet(combinedData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Capacity Roadmap');
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });


        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
        if (tableIndex === -1) link.download = 'Capacity Roadmap.xlsx';
        link.click();


    };



    const closeUploadPopUpOpen = () => {
        setOpenUploadPopup(false)
    }

    const getFilters = async (uuid) => {
        if (uuid === "Home") {
            const currentTab = parseInt(sessionStorage.getItem("tab"));
            setActiveTab(currentTab);
        } else if (uuid) {
            const config = {
                headers: {
                    "content-type": "application/json",
                    serviceid: sessionStorage.getItem("userToken")
                },
            };
            let copyLinkFilters = {}
            try {
                const response = await axios.get(
                    baseConfig.apiBaseUrl + "/VanityURL/GetURL/" + uuid,
                    config
                );
                if (response.status === 200) {
                    if (response.data) {

                        copyLinkFilters = JSON.parse(response.data);
                        var isAllowed = false;
                        if (response.data.includes("EaFilter") && sessionStorage.getItem("AvailableRoles").includes("EarlyAccessPreivew"))
                            isAllowed = true;
                        else if ((response.data.includes("ptuTriage") || response.data.includes("ptuManageTriage")) && sessionStorage.getItem("AvailableRoles").includes(","))
                            isAllowed = true;

                        else if ((response.data.includes("ptuTriage") || response.data.includes("ptuManageTriage")) && !sessionStorage.getItem("AvailableRoles").includes("EarlyAccessPreivew"))
                            isAllowed = true;

                        if (!isAllowed) {
                            showToast("You don’t have access to this tab. Please write to Priya Devakumar to request access.");
                            navigate("/errorPage");
                        }
                    } else {
                        showToast("The link you have copied seems to be incorrect. Please check and try again.");
                        navigate("/errorPage");

                    }
                }
            } catch (error) {
                // setPreFilterSelected(null);
                console.error(error);
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
            filterArray = copyLinkFilters
            if (copyLinkFilters) {
                if (filterArray !== null && Array.isArray(filterArray)) {

                    for (var i = 0; i < filterArray.length; i++) {
                        if (filterArray[i].property === 'ptuTriage') {
                            sessionStorage.setItem('tab', '1');
                            sessionStorage.setItem('copyLinkuuid', uuid)
                            setActiveTab(1)
                            sessionStorage.setItem("ptuFilter", JSON.stringify(filterArray || []))
                            break;
                        }
                        if (filterArray[i].property === 'ptuManageTriage') {
                            sessionStorage.setItem('tab', '2');
                            sessionStorage.setItem('copyLinkuuid', uuid)
                            setActiveTab(2)
                            sessionStorage.setItem("ptuManageFilter", JSON.stringify(filterArray))
                            break; // Exit the loop once property:ptuTriage is found
                        }
                        if (filterArray[i].property === 'EaFilter') {
                            sessionStorage.setItem('tab', '3');
                            setVisibleTabs([false, false, false, true]);
                            setShowEarlyAccess(true)
                            setActiveTab(3);
                            sessionStorage.setItem("selectedMenu", "earlyAccessPreview")
                            sessionStorage.setItem('copyLinkuuid', uuid)
                            sessionStorage.setItem("EaFilter", JSON.stringify(filterArray))
                            var rolesList=sessionStorage.getItem("AvailableRoles").split(',');
                            sessionStorage.setItem("ActiveRole",rolesList.length>1 ? rolesList[1] : rolesList[0]);
                            break; // Exit the loop once property:ptuTriage is found
                        }
                    }
                } else {
                    console.error('Invalid filterArray format:', filterArray);
                }
            }
        }
    }

    React.useEffect(() => {
        checkUrl();
        const url = new URL(window.location.href);
        const uuid = window.location.href.split('/').pop();
        getFilters(uuid)
        const activeRole = sessionStorage.getItem("ActiveRole");

        if (activeRole) {
            setIsContributor(activeRole.includes("Contributor"));
        }
        const selectedMenu = sessionStorage.getItem('selectedMenu');

        if (selectedMenu === 'capacityRoadMap') {
            setVisibleTabs([true, true, true, false]);
            const currentTab = parseInt(sessionStorage.getItem('tab') || '0');
            setActiveTab(currentTab);
            sessionStorage.setItem('tab', currentTab.toString());
        } else {
            setVisibleTabs([false, false, false, true]);
            setActiveTab(3);
            sessionStorage.setItem('tab', '3');
            setShowEarlyAccess(true);
        }
    }, []);


    return (
        <>
            {showEarlyAccess ? (
                <>
                    <NavBar checkUrl={checkUrl}></NavBar>
                    <div className='rightDiv'>
                        <ul className="tab-list">
                            {tabsData.map((tab, index) => (
                                visibleTabs[index] && (
                                    <li
                                        key={index}
                                        className={sessionStorage.getItem("tab") == index ? 'active-tab' : ''}
                                        onClick={() => handleTabClick(index)}
                                    >
                                        {tab.label}
                                    </li>
                                )
                            ))}
                        </ul>

                        {activeTab === 0 ? (
                            <ul className="tab-list">
                                <li style={{ padding: '5px 20px' }}>
                                    <div
                                        className='tabButton'
                                        onClick={() => exportToExcel(-1)}
                                        style={{ marginRight: '-20px' }}
                                    >
                                        <span style={{ color: '#4B81FF' }}></span>
                                        Export All Tables to Excel
                                    </div>
                                </li>

                                <li style={{ padding: '5px 20px' }}>
                                    {isContributor && (
                                        <div
                                            className='tabButton'
                                            onClick={openPopup}
                                            style={{ margin: '0px' }}
                                        >
                                            <span style={{ color: '#4B81FF' }}>+</span> Add New Region
                                        </div>
                                    )}
                                </li>
                            </ul>
                        ) : isContributor && (
                            <ul className="tab-list">
                                <li style={{ padding: '5px 20px' }}>
                                    <div
                                        className='tabButton'
                                        onClick={downloadTemplate}
                                        style={{ marginRight: '-20px' }}
                                    >
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={downloadImg}
                                                alt="Filter Icon"
                                                style={{ height: '15px', width: '15px', marginRight: '10px' }}
                                            />
                                            Download Template
                                        </span>
                                    </div>
                                </li>
                                <li style={{ padding: '5px 20px' }}>
                                    <div
                                        className='tabButton'
                                        onClick={() => setOpenUploadPopup(true)}
                                        style={{ margin: '0px' }}
                                    >
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={uploadImg}
                                                alt="Filter Icon"
                                                style={{ height: '15px', width: '15px', marginRight: '10px' }}
                                            />
                                            Upload Excel
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        )}

                    </div>
                    <div style={{ width: '100%', textAlign: 'right', fontSize: '10px', right: '20px', position: 'relative', padding: '10px 10px 0 0' }}>
                        {isContributor ? (
                            <span>
                                {sessionStorage.getItem("tab") === "3" ?
                                    "Need help? Send mail to Priya Devakumar (pdevakumar@microsoft.com)" :
                                    "Not seeing a region? Need help? Send mail to Priya Devakumar (pdevakumar@microsoft.com)"
                                }
                            </span>
                        ) : (
                            <span>
                                Want to request for access? <a href="mailto:v-silpapr@microsoft.com, pdevakumar@microsoft.com">Contact Us</a>
                            </span>
                        )}
                    </div>

                    {openUploadPopup && (
                        <>
                            <UploadExcel closeUploadPopUpOpen={closeUploadPopUpOpen}></UploadExcel>
                            <div className="modal-backdrop"></div>
                        </>
                    )}
                    <EarlyAccess />
                </>
            ) : (
                successfulLogin && (
                    <>
                        <NavBar checkUrl={checkUrl}></NavBar>
                        <div className='rightDiv'>
                            <ul className="tab-list">
                                {tabsData.map((tab, index) => (
                                    visibleTabs[index] && (
                                        <li
                                            key={index}
                                            className={sessionStorage.getItem("tab") == index ? 'active-tab' : ''}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {tab.label}
                                        </li>
                                    )
                                ))}
                            </ul>

                            {activeTab === 0 ? (
                                <ul className="tab-list">
                                    <li style={{ padding: '5px 20px' }}>
                                        <div
                                            className='tabButton'
                                            onClick={() => exportToExcel(-1)}
                                            style={{ marginRight: '-20px' }}
                                        >
                                            <span style={{ color: '#4B81FF' }}></span>
                                            Export All Tables to Excel
                                        </div>
                                    </li>

                                    <li style={{ padding: '5px 20px' }}>
                                        {isContributor && (
                                            <div
                                                className='tabButton'
                                                onClick={openPopup}
                                                style={{ margin: '0px' }}
                                            >
                                                <span style={{ color: '#4B81FF' }}>+</span> Add New Region
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            ) : isContributor && (
                                <ul className="tab-list">
                                    <li style={{ padding: '5px 20px' }}>
                                        <div
                                            className='tabButton'
                                            onClick={downloadTemplate}
                                            style={{ marginRight: '-20px' }}
                                        >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={downloadImg}
                                                    alt="Filter Icon"
                                                    style={{ height: '15px', width: '15px', marginRight: '10px' }}
                                                />
                                                Download Template
                                            </span>
                                        </div>
                                    </li>
                                    <li style={{ padding: '5px 20px' }}>
                                        <div
                                            className='tabButton'
                                            onClick={() => setOpenUploadPopup(true)}
                                            style={{ margin: '0px' }}
                                        >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={uploadImg}
                                                    alt="Filter Icon"
                                                    style={{ height: '15px', width: '15px', marginRight: '10px' }}
                                                />
                                                Upload Excel
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            )}

                        </div>
                        <div style={{ width: '100%', textAlign: 'right', fontSize: '10px', right: '20px', position: 'relative', padding: '10px 10px 0 0' }}>
                            {isContributor ? (
                                <span>
                                    Not seeing a region? Need help? Send mail to Priya Devakumar (pdevakumar@microsoft.com)
                                </span>
                            ) : (
                                <span>
                                    Want to request for access? <a href="mailto:v-silpapr@microsoft.com, pdevakumar@microsoft.com">Contact Us</a>
                                </span>
                            )}
                        </div>

                        <div className="tab-content">
                            {activeTab === 0 ? (
                                <CapacityRoadMap
                                    isOpen={isOpen}
                                    openPopup={openPopup}
                                    search={search}
                                    userRole={sessionStorage.getItem("ActiveRole")}
                                    setLiveTableData={setLiveTableData}
                                    setModalWiseTableData={setModalWiseTableData}
                                    setGpt35turbo={setGpt35turbo}
                                    setGpt35turbo16k={setGpt35turbo16k}
                                    setGpt4={setGpt4}
                                    setGpt432k={setGpt432k}
                                    openPTU={openPTU}
                                    openPtu={openPtu}
                                    exportToExcel={exportToExcel}
                                />
                            ) : activeTab === 1 ? (
                                <AllSubmissions
                                    search={search}
                                    setOpenUploadPopup={setOpenUploadPopup}
                                    downloadTemplate={downloadTemplate}
                                />
                            ) : (
                                <AllManageSubmissions
                                    search={search}
                                    setOpenUploadPopup={setOpenUploadPopup}
                                    downloadTemplate={downloadTemplate}
                                />
                            )}
                        </div>

                        {openUploadPopup && (
                            <>
                                <UploadExcel closeUploadPopUpOpen={closeUploadPopUpOpen}></UploadExcel>
                                <div className="modal-backdrop"></div>
                            </>
                        )}
                    </>
                )
            )}
        </>
    );

};

export default MainPage;
