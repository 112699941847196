import React, { useEffect, useState } from 'react';
import { headerLabels, tableFormat } from '../../../config/tableFormat';
import moment from 'moment';

const ApprovalTable = (props) => {
    const [loader, setLoader] = useState(false);
    const excludeHeaders = ['Deprovision Date', 'Request Grant Status', 'Notes', 'Purchase Date', 'Churn Reason', 'Churn Date'];
    
    // Headers for tab 3
    const tab3Headers = ['Customer Type', 'Request ID', 'Customer', 'SUB ID', 'Model', 'Region'];
    
    // Filtered headers and keys
    const filteredHeaderLabels = tableFormat.headerLabels.filter(label => !excludeHeaders.includes(label));
    
    const tableFormatLocal = props.isMail ? { headerLabels: filteredHeaderLabels, keys: filteredHeaderLabels } : tableFormat;
    
    // Get current tab from sessionStorage
    const currentTab = sessionStorage.getItem('tab');
    const renderTab3Table = () => (
        <table style={{ borderCollapse: 'collapse' }} className='auto-width'>
            <thead>
                <tr>
                    {tab3Headers.map((header, index) => (
                        <th key={index} style={headerStyle}>
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.data?.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={{
                            backgroundColor: '#f6f6f6',
                        }}
                    >
                        {tab3Headers.map((header, colIndex) => (
                            <td
                                key={`${rowIndex}-${colIndex}`}
                                style={bodyStyle}
                            >
                                {row[header] || 'NA'}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderDefaultTable = () => (
        <table style={{ borderCollapse: 'collapse' }} className='auto-width'>
            <thead>
                <tr>
                    {tableFormatLocal.headerLabels.map((label, index) => (
                        <th key={index} style={headerStyle}>
                            {label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.data?.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={{
                            backgroundColor: '#f6f6f6',
                        }}
                    >
                        {tableFormatLocal.keys.map((label, colIndex) => (
                            <td
                                key={`${rowIndex}-${colIndex}`}
                                style={bodyStyle}
                                title={`Tag :${row['Tag']}`}
                            >
                                {((row[label] && row[label] !== -1 && row[label] !== '-1')
                                    ? (label === 'DateApproved' ? moment(row[label]).format('MM/DD/YYYY') : row[label])
                                    : 'NA')}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <>
            {currentTab === '3' ? renderTab3Table() : renderDefaultTable()}
            {loader && (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            )}
        </>
    );
};

const headerStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
    backgroundColor: '#e9e9e9',
    color: 'black',
    height: '40px', // Ensure uniform height
    padding: '5px', // Adjust padding to control spacing
    border: '1px solid #ccc', // Add borders for consistent sizing
    width: '150px' // Set a fixed width for uniform cell size
};

const bodyStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'center', // Center text horizontally
    verticalAlign: 'middle', // Center text vertically
    height: '40px', // Ensure uniform height
    padding: '5px', // Adjust padding to control spacing
    border: '1px solid #ccc', // Add borders for consistent sizing
    width: '150px' // Set a fixed width for uniform cell size
};

export default ApprovalTable;