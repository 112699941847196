import React, { useCallback, useEffect, useState } from 'react';
import '../requestApproval/requestApproval.css';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";
import AutoAllocateTable from '../../components/tableComponent/AutoAllocateTable';
function AutoConfigure(props) {
    const [selectedArray, setSelectedArray] = React.useState(props.data?.filter((item, index) => props.selectedIndex?.includes(item.RequestID)));
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [allocating, setAllocating] = useState(false)
    const [nonErroneousSelectedIndex,setNonErroneousSelectedIndex] = useState([]);
    const navigate = useNavigate();
    const update = async () => {
        setAllocating(true)
        if (nonErroneousSelectedIndex) {
            const filteredData = nonErroneousSelectedIndex.map((item) => ({
                RequestID: item.RequestID,
                ModelID:item.ModelID,
                RegionRequested: item.RegionRequested,
                PTU_Requested: item.PTU_Requested,
            }));
            try {
                const response = await axios.post(
                    baseConfig.apiBaseUrl + '/HelpRequest/AutoUpdate',
                    filteredData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "serviceid": sessionStorage.getItem("userToken")
                        },
                    }
                );
                if (response.status === 200) {
                    setAllocating(false)
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(false)
                        window.location.reload();
                    }, 3000);
                }

            } catch (error) {
                console.error('API Error:', error);
                setAllocating(false)
                setFailed(true)
                setTimeout(() => {
                    setFailed(false)
                }, 3000);
                // if (error.response.status === 404) {
                //     showToast(error.response.data)
                // }
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
        }
        else {
            window.location.reload();
        }
    }

    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            props.closeConfirmationModal()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);

    return (
        <>
            <div className="modal-container">
                <div style={{ maxHeight: '80vh' }}>
                    <div className="title">
                        <b>Auto Allocation</b>
                        <i className="fa fa-times close-icon"
                            onClick={props.closeConfirmationModal}
                        ></i>
                    </div>

                    <hr className="divider" />

                    <div className="text" style={{ fontSize: '13.5px', marginBottom: '0'}}>
                        You have selected "Auto Allocation" mode from Allocation Mode. Do you want to
                        approve the changes? If so, please click <b>"Auto Allocate"</b>{' '}
                        button at the bottom of the table. If you want to cancel the request
                        then click on <b>"Cancel"</b> Button.
                    </div>
                    <div className="text" style={{ fontSize: '13.5px', marginBottom: '10px' }}>
                    <b>Note:</b> The list below contains only the records in which the Region and Model columns are specified, and PTU Available has a non-zero value.
                    </div>

                    <div style={{ overflow: 'scroll', maxHeight: '300px' }}>
                        <AutoAllocateTable data={selectedArray} isMail={false} isAutoConfigurable={true} setNonErroneousSelectedIndex={setNonErroneousSelectedIndex}></AutoAllocateTable>
                    </div>

                    <hr className="divider" />

                    <div className="buttons-container">
                        <button
                            className="button button-go-back"
                            onClick={props.closeConfirmationModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="button button-confirm"
                            onClick={update}
                            disabled ={allocating? true : false}
                        ><span>Auto Allocate</span>

                            <span className="tooltip-text">Confirm Allocation</span>
                        </button>
                    </div>
                </div>
            </div>
            {success && <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                Auto Allocated Sucessfully
            </div>}
            {allocating && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                Auto Allocating Records..
            </div>}
            {failed && <div className="alert">
                Failed To Update Record. Try again.
            </div>}
        </>
    );
}

export default AutoConfigure;
