import React, { useCallback, useEffect } from 'react';
import '../bulk_update/bulkUpdate.css';
import './updateTags.css';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { components } from 'react-select';
import Draggable from '../../components/tableComponent/tableComponent/Draggable';
import crossImg from "./../../../assets/cross.png";


const UpdateTags = (props) => {
    const [selectedArray, setSelectedArray] = React.useState(props.data?.filter((item, index) => (sessionStorage.getItem('tab') == '3' ?  props.selectedIndex?.includes(item.RowID) : props.selectedIndex?.includes(item.RequestID)))?.map((item) => ({
        RowId: item.RowID,
        RequestID: item.RequestID,
        RequestGrantStatusID: item.RequestGrantStatusID,
        DateApproved: item.DateApproved,
        DRI_Alias: item.DRI_Alias,
        AssignedToAlias: item.AssignedToAlias,
        Notes: item.Notes,
        ProvisionDate: item.ProvisionDate,
        ChurnDate: item.ChurnDate,
        PurchaseDate: item.PurchaseDate,
        ChurnReason: item.ChurnReason,
        DeprovisionDate: item.DeprovisionDate,
        Tag: item.Tag
    })));
    const [selectedOption, setSelectedOption] = React.useState('Add');
    const [updating, setUpdating] = React.useState(false);
    const [updationFailed, setUpdationFailed] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false)
    const PTUTagsReducer = props.PTUTagsReducer;
    const [selectedTagsOptions, setSelectedTagsOptions] = React.useState([]);
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: "100%",

        }),
        control: (provided) => ({
            ...provided,
            height: '100%',
            width: "100%",
            cursor: 'pointer',
            border: 'none',
            boxShadow: 'none'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
            color: state.isSelected ? 'black' : provided.color,
            cursor: 'pointer',

        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
                display: "none"
            }, scrollbarWidth: 'none', /* Hides scrollbar */
            msOverflowStyle: 'none', /* IE and Edge */
            // whiteSpace: 'nowrap',
            flexWrap: "unset",
            fontFamily:
                'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
            fontSize: "5px",
            fontWeight: "600",
            height: "32px",
            padding: "0px 8px 0px 8px",
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'black'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'black'
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'black',
            ':hover': {
                backgroundColor: 'lightgray',
                color: 'black'
            }
        })
    };

    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                        style={{ marginRight: '8px' }}
                    />
                    <label style={{ margin: 0 }}>{props.label}</label>
                </div>
            </components.Option>
        );
    };
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const handleTagChange = (selectedTagsOptions) => {
        setSelectedTagsOptions(selectedTagsOptions);
    }
    const navigate = useNavigate();



    const update = async () => {
        setUpdating(true)
        let updatedJsonDataArray = selectedArray;
        const selectedLabels = selectedTagsOptions.map
            (option => option.label);


        updatedJsonDataArray = updatedJsonDataArray.map(item => {
            let tags = item.Tag ? item.Tag.split(',').map(tag => tag.trim()) : [];

            if (selectedOption === "Add") {
                selectedLabels.forEach(label => {
                    if (!tags.includes(label)) {
                        tags.push(label);
                    }
                });

            } else {
                tags = tags.filter(tag => !selectedLabels.includes(tag));
            }
            item.Tag = tags.join(', ');

            return item;
        });

        const postData = updatedJsonDataArray;
        try {
            const endpoint = sessionStorage.getItem('tab') == '3' ? '/EARequest/UpdateTag' : '/HelpRequest/BulkUpdate'
            const response = await axios.post(
                baseConfig.apiBaseUrl + endpoint,
                postData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": sessionStorage.getItem("userToken")
                    },
                }
            );
            if (response.status === 200) {
                setUpdating(false)
                setUpdateSuccess(true)
                setTimeout(() => {
                    setUpdateSuccess(false)
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            console.error('API Error:', error);
            setUpdating(false)
            setUpdationFailed(true)
            setTimeout(() => {
                setUpdationFailed(false)
            }, 3000);
            if (error.response.status === 401) {
                navigate("/errorPage");
            }
        }
    }
    const CustomValueContainer = ({ children, ...props }) => {
        const selectedValues = props.getValue();
        const { selectProps, hasValue } = props;
        const handleRemove = (removedValue) => {
            const newValue = selectedValues.filter(val => val.value !== removedValue.value);
            selectProps.onChange(newValue, { action: 'remove-value', removedValue });
        };

        return (
            <components.ValueContainer {...props}>
                <div className="drag-tag" onClick={props.selectProps.onMenuOpen}>
                    <Draggable showScroll={false}>
                        {!hasValue && !props.selectProps.inputValue && (
                            <div className="custom-placeholder">Select Tags</div>
                        )}

                        {selectedValues.map((val, index) => (
                            <span className="tag-value" key={val.value} style={{ marginRight: '8px' }}>
                                {val.label} {"  "}
                                <span className="multiselect-remove">
                                    <img src={crossImg} onClick={() => handleRemove(val)} width={"13px"} />
                                </span>
                            </span>
                        ))}
                    </Draggable>
                </div>
            </components.ValueContainer>
        );
    };
    // const CustomValueContainer = ({ children, ...props }) => {
    //     const selectedValues = props.getValue();
    //     const { selectProps, hasValue } = props;

    //     return (
    //         <components.ValueContainer {...props}>
    //             <div className="drag-tag" onClick={props.selectProps.onMenuOpen}>
    //                 <Draggable showScroll={false}>
    //                     {!hasValue && !props.selectProps.inputValue && (
    //                         <div className="custom-placeholder">Select Tags</div>
    //                     )}
    //                     {selectedValues.map((val, index) => (
    //                         <span className="tag-value" key={val.value} style={{ marginRight: '8px' }}>
    //                             {val.label}
    //                         </span>
    //                     ))}
    //                 </Draggable>
    //             </div>
    //         </components.ValueContainer>
    //     );
    // };
    const MultiSelectCheckbox = ({ selectedOptions, options, onChange }) => {
        const formattedOptions = options.map(option => ({
            value: option.ID,
            label: option.Name
        }));
        return (
            <Select
                closeMenuOnSelect={false}
                components={{ Option: CustomOption, ValueContainer: CustomValueContainer }}
                // components={{ Option: CustomOption, ValueContainer: CustomValueContainer, Input: CustomInput }}
                isMulti
                options={formattedOptions}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                styles={customStyles}
                onChange={onChange}
                value={selectedOptions}
                inputValueRenderer={"Hi"}
            />
        );
    };
    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            props.closeTagUpdate()
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [handleEscapeKeyPress]);

    return (
        <>
            <div className="modal-container modal-container-tags">
                <div>
                    <div className="title">
                        <b>Update Tags</b>
                        <i className="fa fa-times close-icon"
                            onClick={props.closeTagUpdate}
                        ></i>
                    </div>

                    <hr className="divider" />

                    <div className="text" style={{ fontSize: '13.5px' }}>
                        To bulk update the selected records, choose either the "Add Tags" or "Remove Tags" radio button. You can then select multiple tags as needed. To finalize the updates, click on the "Update Tags" button. If you wish to cancel the process at any point, click on the "Cancel" button
                    </div>

                    <div>
                        <div className="radios">
                            <div>
                                <input
                                    disabled={updating ? true : false}
                                    type="radio"
                                    id="Add"
                                    name="Add"
                                    value="Add"
                                    checked={selectedOption === 'Add'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="Add">Add Tags</label>
                            </div>
                            <div>
                                <input
                                    disabled={updating ? true : false}
                                    type="radio"
                                    id="Remove"
                                    name="Remove"
                                    value="Remove"
                                    checked={selectedOption === 'Remove'}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="Remove">Remove Tags</label>
                            </div>
                        </div>
                        <div className="input-container-bulk">
                            <label className="text-container-bulk">Select Tags to {selectedOption}</label>
                            <br />

                            <div style={{ border: '1px solid #888888', borderRadius: '5px', marginTop: '10px' }} >
                                <MultiSelectCheckbox options={PTUTagsReducer} selectedOptions={selectedTagsOptions} onChange={handleTagChange} />
                            </div>

                        </div>
                    </div>

                    <div className="container container-tags">
                        <button
                            className="button button-go-back"
                            onClick={props.closeTagUpdate}
                        >
                            Cancel
                        </button>
                        <div className="button-container">
                            <button disabled={updating ? true : false} onClick={() => { update() }}>Update Tags</button>
                        </div>
                    </div>




                </div>
            </div >
            {updating && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                Updating Records...
            </div>
            }
            {
                updateSuccess && (
                    <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                        Records Updated successfully.
                    </div>
                )
            }

            {
                updationFailed && <div className="alert">
                    Failed To Update Records
                </div>
            }
        </>
    );
};

export default UpdateTags;
