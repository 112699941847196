import React from 'react';
import '../addRegion/addRegion.css';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";

function EditPtu(props) {
    const [selectedArray, setSelectedArray] = React.useState(props.data.data);
    const navigate = useNavigate();
    function showToast(message, status) {
 
        const toaster = document.createElement('div');
        toaster.className = `toaster ${status}-toaster`;
        toaster.style.position = 'fixed';
        toaster.style.top = '5%';
        toaster.style.left = '50%';
        toaster.style.transform = 'translateX(-50%)';

        toaster.style.height = '35px';
        toaster.style.maxWidth = '300px';
        toaster.style.zIndex = 1000;
        toaster.innerText = message;



        document.body.appendChild(toaster);
        setTimeout(() => {
            toaster.style.display = 'none';
        }, 5000);
    }

    const update = async () => {
        if (props.type !== 'ptu') {
            selectedArray.ModelID = props.header
        }
        const postData = {
            Region: selectedArray.Region,
            PTUBudgetedToSell:selectedArray.PTUBudgetedToSell
        }
        const url = props.type === 'ptu' ? '/CapacityRoadMap/UpdatePTUBudgetedToSell' : '/CapacityRoadMap/UpdateAvailablePTUByModel'
        try {
            const response = await axios.post(
                baseConfig.apiBaseUrl + url,
                postData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid":sessionStorage.getItem("userToken")
                    },
                }
            );
            if(response.status === 200){
                props.handleModal()
                showToast(response.data, "success");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } 
         } catch (error) {
            console.error('API Error:', error);
            if (error.response.status === 401) {
                navigate("/errorPage");
            }
        }
    }
    const closeModal = () => {
        props.handleModal()
    }

    const handleInputChange = (e, keyvalue) => {
        const newValue = e.target.value;
        const updatedArray = {...selectedArray};
        if (newValue !== ''){
            updatedArray[keyvalue] = newValue;
        }
        setSelectedArray(updatedArray);
    };

    return (
        <div className="modal-container-add " style={{width: 'fit-content'}}>
            <div style={{ maxHeight: '80vh'}}>
                <div className="title-add">
                    {props.type === 'ptu'?
                    <b>Edit PTU Budgeted To Sell</b>
                    :
                    <b>Edit Managed PTUs</b>
                    }
                <i
                 className="fa fa-times close-icon"
                 onClick={closeModal}
                ></i>
             
                </div>

                <hr className="divider-add" />

                <div className="grid-container-add">

                    <div className="grid-item-add" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px', paddingRight:'20px' }}>
                        <div className="input-container-add">
                            <label className="text-container-add">Region Name</label>
                            <input
                                type="text"
                                disabled
                                value={selectedArray?.Region}
                                className="custom-input-add disabled"
                            />          
                        </div>
                    </div>
                
                    <div className="grid-item-add" style={{ flexBasis: '2.4%', fontSize: '14px', marginBottom: '0px', paddingRight:'20px' }}>
                        <div className="input-container-add">
                            <label className="text-container-add">PTUs budgeted to sell</label>
                            <input
                                type="number"
                                defaultValue={selectedArray?.PTUBudgetedToSell}
                                placeholder="Enter text"
                                className="custom-input-add"
                                onChange={(e) => {handleInputChange(e, 'PTUBudgetedToSell')}}
                            />                                  

                        </div>
                    </div>
                </div>

            <hr className="divider-add" />
                <div className="buttons-container-add marginBottom">
                    <button
                        className="button-add button-go-back-add"
                        onClick={closeModal}
                    >
                       Cancel
                    </button>
                    <button
                        className="button-add button-confirm-add"
                        onClick={update}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div >
    );
}

export default EditPtu;
