import { createSlice, current } from "@reduxjs/toolkit";
import {
  fetchTableData,
  fetchRegions,
  distinctData,
  getRegionListforClassic,
  getAllClassicTableData,
  getClassicPtuUsers,
  fetchLiveData,
  fetchPtusData,
  fetchGpt4Data,
  fetchModelNames,
  fetchModalTypeData,
  getManagedUsers,
  getDistinctDataThroughSingleApi,
  getPTUManagedTriageTags,
  getPTUTriageTags,
  getDistinctDataThroughSingleApiEA,
  getEarlyAccessTabledata,
  getEapUsers,
  getEATags
} from "../actions/actions";


const ptuTableDataState = {
  tableData: [],
  isTableDataLoading: true,
  isTableDataLoaded: false,
  isTableDataError: null,
};

export const tableDataReducer = createSlice({
  name: "tableData",
  initialState: ptuTableDataState,
  reducers: {
    updateAllManagedTableData(state, action) {
      const { payload } = action;
      return {
        ...state,
        tableData: payload,
      };
    },
    updateTableDataByRequestId(state, action) {
      const { requestId, updatedObject } = action.payload;
      state.tableData = state.tableData.map((item) => {
        if (item.RequestID === requestId) {
          return updatedObject;
        }
        return item;
      });
    },
    updatePropertyOfSelectedSetInputByRequestId(state, action) {
      const { requestId, propertyValue, label } = action.payload;
      state.tableData = state.tableData.map((item) => {
        if (item.RequestID === requestId) {
          return { ...item, [label]: propertyValue };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTableData.pending, (state) => {
      state.isTableDataLoading = true;
    });
    builder.addCase(fetchTableData.fulfilled, (state, action) => {
      state.tableData = [...action.payload.data];
      state.isTableDataLoading = false;
      state.isTableDataLoaded = true;
    });
    builder.addCase(fetchTableData.rejected, (state, action) => {
      state.isTableDataLoading = false;
      state.isTableDataError = action.error.message;
    });
  },
});

const managedUnchangedTableData = {
  unchangedTableData: [],
  isTableDataLoading: true,
  isTableDataLoaded: false,
  isTableDataError: null,
};
export const UnchangedTableDataReducer = createSlice({
  name: "unchangedTableData",
  initialState: managedUnchangedTableData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTableData.pending, (state) => {
      state.isTableDataLoading = true;
    });
    builder.addCase(fetchTableData.fulfilled, (state, action) => {
      state.unchangedTableData = [...action.payload.data];
      state.isTableDataLoading = false;
      state.isTableDataLoaded = true;
    });
    builder.addCase(fetchTableData.rejected, (state, action) => {
      state.isTableDataLoading = false;
      state.isTableDataError = action.error.message;
    });
  },
});

export const {
  updateAllManagedTableData,
  updateTableDataByRequestId,
  updatePropertyOfSelectedSetInputByRequestId,
} = tableDataReducer.actions;

const ptuTableState = {
  ptuData: {},
  isPtuDataLoading: true,
  isPtuDataLoaded: false,
  isPtuDataError: null,
};

export const ptuTableReducer = createSlice({
  name: "ptutable",
  initialState: ptuTableState,
  reducers: {
    replaceObjectByRequestId(state, action) {
      return action.payload;
    },
    updateModelsPtuGranted(state, action) {
      const { modelKey, updatedData } = action.payload;
      return {
        ...state,
        ptuData: {
          ...state.ptuData,
          [modelKey]: updatedData,
        },
      };
    },
    removePtusFromManagedRegion(state, action) {
      // const { modelKey, oldObject } = action.payload;
      // if (!oldObject) {
      //   return state;
      // }
      // const regionGranted = oldObject['RegionGranted'];
      // const PTU_Granted = oldObject['PTU_Granted'];
      // return {
      //   ...state,
      //   ptuData: {
      //     ...state.ptuData,
      //     [modelKey]: state.ptuData[modelKey].map(element => {
      //       const plainElement = { ...element };
      //       if (plainElement.Region === regionGranted) {
      //         const ptuRequestedinState = parseInt(plainElement.AllotedPTU, 10);
      //         if (isNaN(ptuRequestedinState)) {
      //           return {
      //             ...element,
      //             AllotedPTU: 0
      //           };
      //         }
      //         return {
      //           ...element,
      //           AllotedPTU: ptuRequestedinState - PTU_Granted
      //         };
      //       }
      //       return element;
      //     })
      //   },
      // };
    },
    ptusChangeManagedRegion(state, action) {
      // const { modelKey, updatedObject } = action.payload;
      // if (!updatedObject) {
      //   return state;
      // }
      // const regionGranted = updatedObject['RegionGranted'];
      // const PTU_Granted = updatedObject['PTU_Granted'];
      // return {
      //   ...state,
      //   ptuData: {
      //     ...state.ptuData,
      //     [modelKey]: state.ptuData[modelKey].map(element => {
      //       const plainElement = { ...element };
      //       if (plainElement.Region === regionGranted) {
      //         return {
      //           ...element,
      //           AllotedPTU: PTU_Granted
      //         };
      //       }
      //       return element;
      //     })
      //   },
      // };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.ptuData = action.payload.data;
      state.isPtuDataLoading = false;
      state.isPtuDataLoaded = true;
    });
    builder.addCase(fetchRegions.rejected, (state, action) => {
      state.isLoading = false;
      state.isPtuDataError = action.error.message;
    });
  },
});

export const { updateModelsPtuGranted, removePtusFromManagedRegion, ptusChangeManagedRegion } = ptuTableReducer.actions;

const allManagedCustomersState = {
  allManagedCustomers: [],
  isAllManagedCustomersLoading: true,
  isAllManagedCustomersLoaded: false,
  isAllManagedCustomersError: null,
};

export const allManagedCustomersReducer = createSlice({
  name: "allManagedCustomersReducer",
  initialState: allManagedCustomersState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManagedUsers.pending, (state) => {
      state.isAllManagedCustomersLoading = true;
    });
    builder.addCase(getManagedUsers.fulfilled, (state, action) => {
      state.allManagedCustomers = [...action.payload.data];
      state.isAllManagedCustomersLoading = false;
      state.isAllManagedCustomersLoaded = true;
    });
    builder.addCase(getManagedUsers.rejected, (state, action) => {
      state.isAllManagedCustomersLoading = false;
      state.isAllManagedCustomersError =
        action.isAllManagedCustomersError.message;
    });
  },
});


const distinctDataThroughSingleApiState = {
  distinctDataThroughSingleApi: {},
  isDistinctDataThroughSingleApiLoading: true,
  distinctDataThroughSingleApiError: null,
  isDistinctDataThroughSingleApiLoaded: false,
};

export const distinctDataThroughSingleApiReducer = createSlice({
  name: "distinctDataThroughSingleApi",
  initialState: distinctDataThroughSingleApiState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistinctDataThroughSingleApi.pending, (state) => {
      state.isDistinctDataThroughSingleApiLoading = true;
    });
    builder.addCase(getDistinctDataThroughSingleApi.fulfilled, (state, action) => {
      state.isDistinctDataThroughSingleApiLoading = false;
      state.isDistinctDataThroughSingleApiLoaded = true;
      state.distinctDataThroughSingleApi = action.payload.data;
    });
    builder.addCase(getDistinctDataThroughSingleApi.rejected, (state, action) => {
      state.isDistinctDataThroughSingleApiLoading = false;
      state.distinctDataThroughSingleApiError = action.distinctDataError.message;
    });
  },
});

const initialStatedistinctDataSlice = {
  eaData: [],
  isDistinctDataThroughSingleApiEALoading: true,
  isDistinctDataThroughSingleApiEALoaded: true,
  error: null,
};
export const eaSlice = createSlice({
  name: "eaData",
  initialState: initialStatedistinctDataSlice,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDistinctDataThroughSingleApiEA.pending, (state) => {
      state.isDistinctDataThroughSingleApiEALoading = true;
    })
    .addCase(getDistinctDataThroughSingleApiEA.fulfilled, (state, action) => {
        state.eaData = action.payload.data; 
        state.isDistinctDataThroughSingleApiEALoading = false;
        state.isDistinctDataThroughSingleApiEALoaded = true;
      })
      .addCase(getDistinctDataThroughSingleApiEA.rejected, (state, action) => {
        state.error = action.error; // Handle errors
      });
  },
});
const PTUManagedTriageTagsState = {
  PTUManagedTriageTagsState: [],
  isTagsLoading: true,
  PTUManagedTriageTagsError: null,
  isTagsLoaded: false,
};

export const PTUManagedTriageTagsReducer = createSlice({
  name: "PTUManagedTriageTags",
  initialState: PTUManagedTriageTagsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPTUManagedTriageTags.pending, (state) => {
      state.isTagsLoading = true;
    });
    builder.addCase(getPTUManagedTriageTags.fulfilled, (state, action) => {
      state.isTagsLoading = false;
      state.isTagsLoaded = true;
      state.PTUManagedTriageTagsState = action.payload.data;
    });
    builder.addCase(getPTUManagedTriageTags.rejected, (state, action) => {
      state.isTagsLoading = false;
      state.PTUManagedTriageTagsError = action.distinctDataError.message;
    });
  },
});
const EAPTagsState = {
  EAPTagsState: [],
  isTagsLoading: true,
  EAPTagsError: null,
  isTagsLoaded: false,
};

export const EAPTagsReducer = createSlice({
  name: "EAPTagsReducer",
  initialState: EAPTagsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEATags.pending, (state) => {
      state.isTagsLoading = true;
    });
    builder.addCase(getEATags.fulfilled, (state, action) => {
      state.isTagsLoading = false;
      state.isTagsLoaded = true;
      state.EAPTagsState = action.payload.data;
    });
    builder.addCase(getEATags.rejected, (state, action) => {
      state.isTagsLoading = false;
      state.EAPTagsError = action.distinctDataError.message;
    });
  },
});

const PTUTriageTagsState = {
  PTUTriageTagsState: [],
  isTagsLoading: true,
  PTUTriageTagsError: null,
  isTagsLoaded: false,
};

export const PTUTriageTagsReducer = createSlice({
  name: "PTUTriageTags",
  initialState: PTUTriageTagsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPTUTriageTags.pending, (state) => {
      state.isTagsLoading = true;
    });
    builder.addCase(getPTUTriageTags.fulfilled, (state, action) => {
      state.isTagsLoading = false;
      state.isTagsLoaded = true;
      state.PTUTriageTagsState = action.payload.data;
    });
    builder.addCase(getPTUTriageTags.rejected, (state, action) => {
      state.isTagsLoading = false;
      state.PTUTriageTagsError = action.distinctDataError.message;
    });
  },
});

const ptuTableClassicState = {
  regionDataClassic: [],
  isPtuTableClassicDataLoading: true,
  error: null,
  isPtuTableClassicDataLoaded: false,
};

export const regionDataClassicReducer = createSlice({
  name: "regionDataClassic",
  initialState: ptuTableClassicState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRegionListforClassic.pending, (state) => {
      state.isPtuTableClassicDataLoading = true;
    });
    builder.addCase(getRegionListforClassic.fulfilled, (state, action) => {
      state.isPtuTableClassicDataLoading = false;
      // state.ptuTableClassicData = [...state.regionDataClassic, action.payload];
      state.regionDataClassic = [...action.payload.data];

      state.isLoaded = true;
    });
    builder.addCase(getRegionListforClassic.rejected, (state, action) => {
      state.isPtuTableClassicDataLoading = false;
      state.error = action.error.message;
    });
  },
});

const classicTableData = {
  classicTableData: [],
  isClassicTableDataLoading: true,
  isClassicTableDataLoaded: false,
  classicTableDataError: null,
};

export const classicTableDataReducer = createSlice({
  name: "classicTableData",
  initialState: classicTableData,
  reducers: {
    updateAllClassicTableData(state, action) {
      const { payload } = action;
      return {
        ...state,
        classicTableData: payload,
      };
    },
    updateClassicTableDataByRequestId(state, action) {
      const { requestId, updatedObject } = action.payload;
      state.classicTableData = state.classicTableData.map((item) => {
        if (item.RequestID === requestId) {
          return updatedObject;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClassicTableData.pending, (state) => {
      state.isClassicTableDataLoading = true;
    });
    builder.addCase(getAllClassicTableData.fulfilled, (state, action) => {
      state.classicTableData = [...action.payload.data];
      state.isClassicTableDataLoading = false;
      state.isClassicTableDataLoaded = true;
    });
    builder.addCase(getAllClassicTableData.rejected, (state, action) => {
      state.isClassicTableDataLoading = false;
      state.classicTableDataError = action.classicTableDataError.message;
    });
  },
});
const unchngedClassicTableData = {
  classicTableData: [],
  isClassicTableDataLoading: true,
  isClassicTableDataLoaded: false,
  classicTableDataError: null,
};

export const UnchangedClassicTableDataReducer = createSlice({
  name: "classicTableData",
  initialState: unchngedClassicTableData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllClassicTableData.pending, (state) => {
      state.isClassicTableDataLoading = true;
    });
    builder.addCase(getAllClassicTableData.fulfilled, (state, action) => {
      state.classicTableData = [...action.payload.data];
      state.isClassicTableDataLoading = false;
      state.isClassicTableDataLoaded = true;
    });
    builder.addCase(getAllClassicTableData.rejected, (state, action) => {
      state.isClassicTableDataLoading = false;
      state.classicTableDataError = action.classicTableDataError.message;
    });
  },
});

export const { updateClassicTableDataByRequestId, updateAllClassicTableData } =
  classicTableDataReducer.actions;

const allClassicCustomersState = {
  allClassicCustomers: [],
  isAllClassicCustomersLoading: true,
  isAllClassicCustomersLoaded: false,
  isAllClassicCustomersError: null,
};

export const allClassicCustomersReducer = createSlice({
  name: "allClassicCustomers",
  initialState: allClassicCustomersState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClassicPtuUsers.pending, (state) => {
      state.isAllClassicCustomersLoading = true;
    });
    builder.addCase(getClassicPtuUsers.fulfilled, (state, action) => {
      state.allClassicCustomers = [...action.payload.data];
      state.isAllClassicCustomersLoading = false;
      state.isAllClassicCustomersLoaded = true;
    });
    builder.addCase(getClassicPtuUsers.rejected, (state, action) => {
      state.isAllClassicCustomersLoading = false;
      state.isAllClassicCustomersError =
        action.isAllClassicCustomersError.message;
    });
  },
});

const alleapCustomersState = {
  allEAPCustomers: [],
  isAllEAPCustomersLoading: true,
  isAllEAPCustomersLoaded: false,
  isAllEAPCustomersError: null,
};

export const allEAPCustomers = createSlice({
  name: "allEAPCustomers",
  initialState: alleapCustomersState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEapUsers.pending, (state) => {
      state.isAllEAPCustomersLoading = true;
    });
    builder.addCase(getEapUsers.fulfilled, (state, action) => {
      state.allEAPCustomers = [...action.payload.data];
      state.isAllEAPCustomersLoading = false;
      state.isAllEAPCustomersLoaded = true;
    });
    builder.addCase(getEapUsers.rejected, (state, action) => {
      state.isAllEAPCustomersLoading = false;
      state.isAllEAPCustomersError =
        action.isAllEAPCustomersError.message;
    });
  },
});

const liveDataState = {
  liveData: [],
  isLiveDataLoading: true,
  isLiveDataLoaded: false,
  liveDataError: null,
};

export const liveDataReducer = createSlice({
  name: "liveData",
  initialState: liveDataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLiveData.pending, (state) => {
      state.isLiveDataLoading = true;
    });
    builder.addCase(fetchLiveData.fulfilled, (state, action) => {
      state.isLiveDataLoading = false;
      state.isLiveDataLoaded = true;
      state.liveData = [...action.payload.data];
    });
    builder.addCase(fetchLiveData.rejected, (state, action) => {
      state.isLiveDataLoading = false;
      state.liveDataError = action.error.message;
    });
  },
});

const ptusDataState = {
  ptusData: [],
  isPtusDataLoading: true,
  isPtusDataLoaded: false,
  ptusDataError: null,
};

export const ptusDataReducer = createSlice({
  name: "ptusData",
  initialState: ptusDataState,
  reducers: {
    updateClassicRegionGranted(state, action) {
      const { updatedData } = action.payload;
      return {
        ...state,
        ptusData: [...updatedData],
      };
    },
    removePtusFromClassicRegion(state, action) {
      const { oldObject } = action.payload;
      if (!oldObject) {
        return state;
      }

      const regionGranted = oldObject['RegionGranted'];
      const PTU_Granted = oldObject['PTU_Granted'];

      const updatedPtuData = state.ptusData.map(element => {
        const plainElement = { ...element };
        if (plainElement.Region === regionGranted) {
          const ptuRequestedInState = parseInt(plainElement.AllotedPTU, 10);
          if (isNaN(ptuRequestedInState)) {
            return {
              ...plainElement,
              AllotedPTU: 0,
            };
          }
          return {
            ...plainElement,
            AllotedPTU: ptuRequestedInState - PTU_Granted,
          };
        }
        return plainElement;
      });
      return {
        ...state,
        ptusData: updatedPtuData,
      };
    },
    ptusChangeClassicRegion(state, action) {
      const { updatedObject } = action.payload;
      if (!updatedObject) {
        return state;
      }

      const regionGranted = updatedObject['RegionGranted'];
      const PTU_Granted = updatedObject['PTU_Granted'];

      const updatedPtuData = state.ptusData.map(element => {
        const plainElement = { ...element };

        if (plainElement.Region === regionGranted) {
          return {
            ...plainElement,
            AllotedPTU: PTU_Granted,
          };
        }
        return plainElement;
      });
      return {
        ...state,
        ptusData: updatedPtuData,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPtusData.pending, (state) => {
      state.isPtusDataLoading = true;
    });
    builder.addCase(fetchPtusData.fulfilled, (state, action) => {
      state.ptusData = [...action.payload.data];
      state.isPtusDataLoading = false;
      state.isPtusDataLoaded = true;
    });
    builder.addCase(fetchPtusData.rejected, (state, action) => {
      state.isPtusDataLoading = false;
      state.ptusDataError = action.error.message;
    });
  },
});

export const { updateClassicRegionGranted, removePtusFromClassicRegion, ptusChangeClassicRegion } = ptusDataReducer.actions;

const gpt4DataState = {
  gpt4Data: [],
  isGpt4DataDataLoading: true,
  isGpt4DataDataLoaded: false,
  gpt4DataError: null,
};

export const gpt4DataReducer = createSlice({
  name: "gpt4Data",
  initialState: gpt4DataState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGpt4Data.pending, (state) => {
      state.isGpt4DataDataLoading = true;
    });
    builder.addCase(fetchGpt4Data.fulfilled, (state, action) => {
      state.gpt4Data = [...action.payload.data];
      state.isGpt4DataDataLoading = false;
      state.isGpt4DataDataLoaded = true;
    });
    builder.addCase(fetchGpt4Data.rejected, (state, action) => {
      state.isGpt4DataDataLoading = false;
      state.gpt4DataError = action.error.message;
    });
  },
});

const modelNamesState = {
  modalNamesData: [],
  isModalNamesDataLoading: true,
  isModalNamesDataLoaded: false,
  modelNamesDataError: null,
};

export const modelNamesReducer = createSlice({
  name: "modelNamesData",
  initialState: modelNamesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModelNames.pending, (state) => {
      state.isModalNamesDataLoading = true;
    });
    builder.addCase(fetchModelNames.fulfilled, (state, action) => {
      state.modalNamesData = [...action.payload.data];
      state.isModalNamesDataLoading = false;
      state.isModalNamesDataLoaded = true;
    });
    builder.addCase(fetchModelNames.rejected, (state, action) => {
      state.isModalNamesDataLoading = false;
      state.modelNamesDataError = action.error.message;
    });
  },
});

const modalTypeState = {
  modalTypeData: [],
  isModalTypeDataLoading: true,
  isModalTypeDataLoaded: false,
  modelTypeDataError: null,
};

export const modalTypeReducer = createSlice({
  name: "modalTypeData",
  initialState: modalTypeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchModalTypeData.pending, (state) => {
      state.isModalTypeDataLoading = true;
    });
    builder.addCase(fetchModalTypeData.fulfilled, (state, action) => {
      state.isModalTypeDataLoading = false;
      state.isModalTypeDataLoaded = true;
      state.modalTypeData = action.payload.data;
    });
    builder.addCase(fetchModalTypeData.rejected, (state, action) => {
      state.isModalTypeDataLoading = false;
      state.modelTypeDataError = action.error.message;
    });
  },
});

const EaTableData = {
  tableData: [],
  isTableDataLoading: true,
  isTableDataLoaded: false,
  TableDataError: null,
};

export const eaTableData = createSlice({
  name: "EaTableData",
  initialState: EaTableData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEarlyAccessTabledata.pending, (state) => {
      state.isTableDataLoading = true;
    });
    builder.addCase(getEarlyAccessTabledata.fulfilled, (state, action) => {
      state.isTableDataLoading = false;
      state.isTableDataLoaded = true;
      state.tableData = action.payload.data;
    });
    builder.addCase(getEarlyAccessTabledata.rejected, (state, action) => {
      state.isTableDataLoading = false;
      state.TableDataError = action.error.message;
    });
  },
});

// export default ptuTableReducer.reducer
