import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import baseConfig from '../../../config/config';

const MicrosoftLogin2 = (props) => {
  const location = useLocation();
  const [href, setHref] = useState("")
  const [userName, setUserName] = useState("");
  const [calledSignIn, setCalledSignIn] = useState(false);
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();
 
  const checkAuthorization = async (email, userName) => {
    const postData = {
      email: email,
    };
    try {
      const response = await axios.post(
        baseConfig.apiBaseUrl + "/Utility/IsAllowed",
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid": sessionStorage.getItem("userToken")
          },
        }
      );

      // setIsEmailAuthorized(true);
      navigate("/Home");
    } catch (error) {
      console.error("API Error:", error);
      // setIsEmailAuthorized(false);
      navigate("/errorPage");
    }
  };

  const getAndSetRoles = async (email, userName) => {

    try {
      const response = await axios.post(
        baseConfig.apiBaseUrl + `/Utility/Role`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid": sessionStorage.getItem("userToken")
          },
        }
      );
      return response;

    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("userName") && sessionStorage.getItem("Emailid")) {
      checkAuthorization(sessionStorage.getItem("Emailid"), sessionStorage.getItem("userName"));
    }
  }, [])

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((s) => {
        const email = s.account.idTokenClaims.email;
        const username = s.account.name;
        if (baseConfig.allowedDomains.some(domain => email.includes(domain))){
          const accountId = s.account.localAccountId;
          setUserName(username);
          sessionStorage.setItem("userName", username);
          sessionStorage.setItem("Emailid", email);
          sessionStorage.setItem("accountId", accountId);
          const accessToken = s.accessToken;
          sessionStorage.setItem("userToken", accessToken);

          getAndSetRoles(email, username).then((response) => {
            const rolesString = response.data;
            const rolesList = rolesString.split(',').map(role => role.trim());
            const activeRole = rolesList[0];
            sessionStorage.setItem('AvailableRoles', rolesString);
            sessionStorage.setItem('ActiveRole', activeRole);
            sessionStorage.setItem('hasMultipleRoles', rolesList.length > 1 ? 'true' : 'false');

            if (activeRole.includes('EarlyAccess')) {
              sessionStorage.setItem("tab", "3");
              sessionStorage.setItem("selectedMenu", "earlyAccessPreview");
            }
            else {
              sessionStorage.setItem("selectedMenu", "capacityRoadMap");
              sessionStorage.setItem("tab", "0");
            }
            if (sessionStorage.getItem("href")) {
              window.location.replace(sessionStorage.getItem("href"))
              sessionStorage.removeItem("href")
            }
            else {
              checkAuthorization(email, username)
              // navigate('/Home')
            }
          });


        }
        else {
          navigate("/unauthorizedaccess");
        }
      }).catch((error) => {
        console.error("Failed to login into application", error);
      });
    const accounts = instance.getAllAccounts();
    if ((inProgress === InteractionStatus.None) && !calledSignIn && (!accounts || accounts.length <= 0)) {
      signIn();
      setCalledSignIn(true);
    }
  }, [inProgress, calledSignIn]);

  function signIn() {
         const loginScope = {
        scopes: ["User.Read", "openid", "profile", "offline_access", "email", "User.ReadBasic.All"],
        state: window.location.origin
      };
      instance.loginRedirect(loginScope);
  }

  return (
    <div className="loader-container" style={{ height: "100vh" }}>
      <div className="loader"></div>
    </div>
  );
};

export default MicrosoftLogin2;
